import React from "react";
import { useEmployerReviews } from "../../API/reviewsApi";
import { StarIcon } from "@heroicons/react/20/solid";
import { classNames } from "../../utils/utils";
const RecentReviewChart = () => {
  const {
    data: employerReviewMade,
    refetch,
    isLoading,
    isError,
  } = useEmployerReviews(true);

  const getEmployerName = (userId: string) => {
    return employerReviewMade?.labourers?.find(
      (labourers: any) => labourers?.id === userId
    )?.name;
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-64">Loading...</div>
    );
  if (isError)
    return (
      <div className="flex justify-center items-center h-64 text-red-500">
        Error loading reviews
      </div>
    );

  const sortedReviews = employerReviewMade?.employerReviews
    ?.sort(
      (a: any, b: any) =>
        new Date(b.reviewUpdatedOn).getTime() -
        new Date(a.reviewUpdatedOn).getTime()
    )
    .slice(0, 4);
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden">
      <div className="bg-gray-50 px-4 py-5 border-b border-gray-200 sm:px-6">
        <h3 className="text-lg leading-6 font-bold text-black">
          Recent Ratings & Reviews
        </h3>
      </div>
      <ul className="divide-y divide-gray-200">
        {sortedReviews?.map((item: any, index: number) => {
          const employerName = getEmployerName(item?.labourerId);
          return (
            <li
              key={index}
              className="px-4 py-4 sm:px-6 hover:bg-gray-50 transition duration-150 ease-in-out"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img
                    className="h-12 w-12 rounded-full object-cover"
                    src={
                      item.profileUrl ||
                      require("../../assets/images/user_default.png")
                    }
                    alt={`${employerName}'s profile`}
                  />
                  <div className="ml-4">
                    <div className="text-sm font-medium text-indigo-600">
                      {employerName}
                    </div>
                    <div className="text-sm text-gray-500">{item?.title}</div>
                    <div className="text-xs text-gray-400">
                      {formatDate(item?.reviewUpdatedOn)}
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  {/* <div className="flex">
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          item?.employerRatings[0].rating > rating
                            ? "text-yellow-400"
                            : "text-gray-300",
                          "h-5 w-5 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                    ))}
                  </div> */}
                  <div className="ml-2 text-sm text-gray-500">
                    {/* {item?.employerRatings[0].rating.toFixed(1)} */}
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default RecentReviewChart;
