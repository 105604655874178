import React, { Fragment, useEffect, useRef, useState } from "react";

import { Transition, Listbox, Menu } from "@headlessui/react";
import { Dialog } from "@material-tailwind/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LogOut, LogoIcon, ProfileIcon } from "../assets/images/AllSvg";
import { RiMenu3Fill } from "react-icons/ri";
import { AiFillCaretDown, AiFillCaretRight } from "react-icons/ai";
import Login from "./Login/Login";
import { useTranslation } from "react-i18next";
import {
  updateEmployerAvailable,
  updateEmployerBusy,
  useAvailability,
  useEmployerAvailability,
  useLanguagesQuery,
  useUserMeQuery,
} from "../API/api";
import {
  CheckBadgeIcon,
  CheckIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { clearToken, isAuthenticated } from "../utils/jwtUtils";
import { smartStorage } from "../utils/SmartStorage";
import { Availabilities, UserRole, UserType } from "../API/types";
import { classNames, isEmployer, isLabourer, hasRole } from "../utils/utils";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import NotificationBox from "./Notification/NotificationBox";
const tmIcon = require("../assets/images/tm-1.png");
const mlIcon = require("../assets/images/ml-1.png");
const hiIcon = require("../assets/images/hi-2.png");
const enIcon = require("../assets/images/en-1.png");
const asIcon = require("../assets/images/as-1.png");
const bgIcon = require("../assets/images/bg-1.png");

const nav = [
  { name: "ABOUT", route: "/about" },
  { name: "SERVICES", route: "/servicecategory" },
  { name: "BENEFITS", route: "/benefits" },
  { name: "SOLUTIONS", route: "/solutions" },
  { name: "SUPPORT", route: "/support" },
  // { name: "NOTIFICATIONS", route: "/notification" },
];

const langdata = [
  {
    id: 1,
    name: "English",
    code: "en",
    shortName: "E",
    borderColor: "border-yellow-500",
    imageName: "en-1.png",
    imageIcon: enIcon,
  },
  {
    id: 2,
    // name: "Hindi",
    code: "hi",
    name: "हिंदी",
    shortName: "ह",
    borderColor: "border-yellow-300",
    imageName: "hi-2.png",
    imageIcon: hiIcon,
  },
  {
    id: 3,
    // name: "Malayalam",
    code: "ml",
    name: "മലയാളം",
    shortName: "മ",
    borderColor: "border-green-300",
    imageName: "ml-1.png",
    imageIcon: mlIcon,
  },
  {
    id: 4,
    // name: "Tamil",
    code: "ta",
    name: "தமிழ்",
    shortName: "த",
    borderColor: "border-red-500",
    imageName: "tm-1.png",
    imageIcon: tmIcon,
  },
  {
    id: 5,
    // name - Assamese
    code: "as",
    name: "অসমীয়া",
    shortName: "অ",
    borderColor: "border-red-500",
    imageName: "tm-1.png",
    imageIcon: asIcon,
  },
  {
    id: 6,
    // name: Bengali
    code: "bg",
    name: "বাংলা",
    shortName: "বা",
    borderColor: "border-red-500",
    imageName: "tm-1.png",
    imageIcon: bgIcon,
  },
];

const Navbar = ({
  open,
  setOpen,
  isScroll,
  isScrollSolution,
  isScrollBenefits,
  isScrollServices,
  preSelectedUserType,
  resetPreselectedUserType,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isScroll: () => void;
  isScrollSolution: () => void;
  isScrollBenefits: () => void;
  isScrollServices: () => void;
  preSelectedUserType: UserType | undefined;
  resetPreselectedUserType: () => void;
}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [logoutOpen, setlogoutOpen] = useState<boolean>(false);
  const [employeeaslabourer, setemployeeaslabourer] = useState(false);
  const getPath = useLocation();
  const [lang, setLang] = useState("en");

  const conditionVariable =
    window.location.pathname === "/dashboard" ||
    window.location.pathname === "/searchservices" ||
    window.location.pathname === "/jobstatistics" ||
    window.location.pathname === "/favourites" ||
    window.location.pathname === "/rates&review" ||
    window.location.pathname === "/paymenthistory" ||
    window.location.pathname === "/referrals" ||
    window.location.pathname === "/messageCenter" ||
    window.location.pathname === "/settings" ||
    window.location.pathname === "/profile" ||
    window.location.pathname === "/contact-us" ||
    window.location.pathname === "/acceptable-policy" ||
    window.location.pathname === "/aboutus" ||
    window.location.pathname === "/terms-and-conditions" ||
    window.location.pathname === "/disclaime-policy" ||
    window.location.pathname === "/privacy-policy" ||
    window.location.pathname === "/faqs" ||
    window.location.pathname === "/report-abuse" ||
    window.location.pathname === "/refund-and-cancellation" ||
    window.location.pathname === "/home" ||
    window.location.pathname === "/subscribe";

  const handleLogOut = () => {
    resetPreselectedUserType();
    clearToken();
    smartStorage.handleLogout();
    const cookie = {
      name: "jwt",
      expires: new Date(Date.now() - 1000),
      httpOnly: true,
    };
    const cookieString = JSON.stringify(cookie, (key, value) => {
      if (key !== "name" && key !== "httpOnly" && key !== "expires") {
        return "";
      }
    });
    smartStorage.removeItem("openChatRoomId");
    document.cookie = cookieString;
    setlogoutOpen(false);
    navigate("/");
  };

  const { t, i18n } = useTranslation();
  const availabilityOptions = [
    { title: t("Available"), id: Availabilities.AVAILABLE },
    { title: t("Busy"), id: Availabilities.UNAVAILABLE },
  ];
  const handleLanguageChange = (selectedLanguageCode: any) => {
    i18n.changeLanguage(selectedLanguageCode);
    setLang(selectedLanguageCode);
  };
  const location = useLocation();
  const { data: userData } = useUserMeQuery();

  const { data: userStatus, refetch: availabilityRefetch } = useAvailability(
    userData?.user.id
  );

  const updateStatusAvailable = useMutation(updateEmployerAvailable, {
    onSuccess: (data) => {
      availabilityRefetch();
    },
    onError: (error) => {
      onStatusUpdateFailed();
      console.error(error);
    },
  });

  const updateStatusBusy = useMutation(updateEmployerBusy, {
    onSuccess: (data) => {
      availabilityRefetch();
    },
    onError: (error) => {
      onStatusUpdateFailed();
      console.error(error);
    },
  });

  const onStatusUpdateFailed = () => {
    toast.error(t("availabilityStatusUpdateFailed"));
  };

  const handleSubMenuClick = async (status: number) => {
    if (status === Availabilities.AVAILABLE) {
      updateStatusAvailable.mutate();
    } else if (status === Availabilities.UNAVAILABLE) {
      updateStatusBusy.mutate();
    }
    setSubMenuOpen(false);
  };

  const [profileUrlError, setProfileUrlError] = useState<boolean>(false);

  const onProfileImageError = () => {
    setProfileUrlError(true);
  };

  const MenuItem = (name: string, active: boolean, onClick: () => void) => {
    return (
      <a
        className={classNames(
          active ? "bg-gray-100" : "",
          "block px-4 py-2 text-sm text-gray-700 text-left"
        )}
      >
        <div onClick={onClick} className="flex gap-4">
          <p className="text-left">{name}</p>
        </div>
      </a>
    );
  };

  const isOnline =
    userStatus != undefined && userStatus.status == Availabilities.AVAILABLE;

  const ProfileImage = () => {
    return (
      <div className="relative h-11 w-11">
        <img
          className="w-full h-full rounded-full"
          src={
            userData?.user?.profileUrl && !profileUrlError
              ? userData.user.profileUrl
              : require("../assets/images/user_default.png")
          }
          alt=""
          onError={onProfileImageError}
        />
        {userData && isLabourer(userData?.user) && (
          <div className="absolute bottom-0 right-0">
            <div
              className={`w-3 h-3 rounded-full border-2 ${
                isOnline
                  ? "bg-green-500 border-green-500"
                  : "bg-red-500 border-red-500"
              }`}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="relative ">
        <div
          className={`${
            conditionVariable
              ? "2xl:pl-[119px] 2xl:pr-[146px] xl:max-w-[1186px]  2xl:max-w-full"
              : "xl:max-w-[1186px]  2xl:max-w-[1440px] 4xl:max-w-full"
          }  mx-auto px-[20px] py-[16px] 4xl:px-[90px] sm:pt-[20px] flex items-center justify-between relative z-[10] max-h-[92px]`}
        >
          <div className="flex items-center">
            <div className="cursor-pointer w-[80px] sm:w-[123px] xl:mr-[40px] mr-[20px] relative">
              <Link to="/">
                <div className="relative">
                  <LogoIcon width={"100%"} />
                  <span className="absolute top-2 left-11 text-xs -translate-x-full -translate-y-1/2">
                    &trade;
                  </span>
                </div>
              </Link>
            </div>

            <ul className="hidden lg:flex xl:gap-[12px] 2xl:gap-[24px] items-center ml-10 sm:gap-[9px] sm:ml-0">
              {/* <ul className="hidden lg:flex xl:gap-[30px] gap-[26px] items-center ml-10"> */}
              {nav.map((val, i) => {
                if (val.name === "NOTIFICATIONS" && !conditionVariable) {
                  return null;
                }
                return (
                  <div>
                    <li key={i}>
                      <button
                        onClick={() => {
                          //console.log("ConditionVariable", conditionVariable);
                          if (val.name === "ABOUT") {
                            if (conditionVariable) {
                              navigate("/", { state: { targetId: "compRef" } });
                            } else {
                              isScroll();
                            }
                          } else if (
                            val.name === "SOLUTIONS" ||
                            val.name === "SUPPORT"
                          ) {
                            if (conditionVariable) {
                              navigate("/", {
                                state: { targetId: "solutionRef" },
                              });
                            } else {
                              isScrollSolution();
                            }
                          } else if (val.name === "BENEFITS") {
                            if (conditionVariable) {
                              navigate("/", {
                                state: { targetId: "benefitsRef" },
                              });
                            } else {
                              isScrollBenefits();
                            }
                          } else if (val.name === "SERVICES") {
                            if (conditionVariable) {
                              navigate("/", {
                                state: { targetId: "servicesRef" },
                              });
                            } else {
                              isScrollServices();
                            }
                          }
                        }}
                        className={`${
                          getPath.pathname === val.route
                            ? "text-[#FFB800]"
                            : "text-[#272D29]"
                        } ${
                          lang !== "en"
                            ? "text-[22px]"
                            : "xl:text-[16px] 4xl:text-[1vw]"
                        } font-medium text-[14px] leading-[28px] `}
                      >
                        {/* {val.name} */}
                        {t(val.name)}
                      </button>
                    </li>
                  </div>
                );
              })}
            </ul>
          </div>
          <div className="flex text-end gap-[10px] items-center ">
            {isAuthenticated() && <NotificationBox />}

            <div className="flex items-center justify-center md:gap-4 sm:gap:0 ">
              <Listbox value={lang} onChange={setLang}>
                {({ open }) => (
                  <>
                    <div className="relative">
                      <Listbox.Button className="4xl:text-[0.9vw] relative w-full cursor-default bg-white h-[36px] pl-3 md:pr-[33px] pr-6 text-left text-gray-900 shadow-sm border-[#E7E7E7] border-[1px] rounded-3xl  text-sm leading-6">
                        <span className="flex gap-[6px] items-center truncate uppercase">
                          {/* <div
                            className={`w-7 h-7 flex items-center justify-center border-2 ${
                              langdata.find((item) => item.code === lang)
                                ?.borderColor
                            }  rounded-full`}
                          >
                            <div className={`bg-white rounded-full `}>
                              {
                                langdata.find((item) => item.code === lang)
                                  ?.shortName
                              }
                            </div>
                          </div> */}

                          <img
                            className="flex items-center justify-center w-6 h-6 border-2 rounded-full"
                            src={
                              langdata.find((item) => item.code === lang)
                                ?.imageIcon
                            }
                            alt={lang}
                          />

                          {lang}
                        </span>
                        <span
                          className={`${
                            open ? "rotate-180 pl-2" : "pr-2"
                          } pointer-events-none absolute inset-y-0 right-0 flex items-center`}
                        >
                          <AiFillCaretDown
                            className="text-[18px]"
                            aria-hidden="true"
                          />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options className="absolute z-10 w-auto py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                          {langdata?.map((person: any) => (
                            <Listbox.Option
                              key={person.id}
                              className={({ active }) =>
                                classNames(
                                  active ? "text-gray-900" : "text-gray-900",
                                  "relative cursor-default select-none py-2 px-3"
                                )
                              }
                              value={person.code}
                              onClick={() => handleLanguageChange(person.code)}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex gap-[6px] items-center w-[108px] ">
                                    <img
                                      className="w-6 h-6 "
                                      // src={require(`../assets/images/${person?.imageName}`).default}
                                      src={person?.imageIcon}
                                      alt=""
                                    />

                                    <span
                                      className={classNames(
                                        selected
                                          ? "font-semibold"
                                          : "font-normal",
                                        "block truncate 4xl:text-[1vw]"
                                      )}
                                    >
                                      {t(person.name)}
                                    </span>
                                  </div>
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
              {conditionVariable ||
              isAuthenticated() /* && selectedSMS === 0 FIX : Login FIX */ ? (
                <Menu as="div" className="relative ml-3">
                  <div>
                    <Menu.Button className="flex items-center gap-2 text-sm rounded-full ring-2 ring-gray-200 hover:ring-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-0">
                      <span className="sr-only">Open user menu</span>
                      {ProfileImage()}
                      <div className="items-center justify-between hidden md:flex">
                        <span
                          hidden
                          className={`${open ? "rotate-90" : ""}
                                pointer-events-none flex items-center pr-2`}
                        >
                          <ChevronDownIcon
                            strokeWidth={2.5}
                            className={`h-3 w-3 transition-transform ${
                              open ? "rotate-180" : ""
                            }`}
                          />
                        </span>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div>
                        <p className="px-4 text-base font-semibold text-left text-gray-700">
                          {userData?.user.name}
                        </p>
                        <p className="px-4 text-sm text-left text-gray-700">
                          {userData?.user.phoneNumber}
                        </p>
                      </div>
                      <div className="h-0.5 mt-2 bg-gray-100"></div>

                      {userData?.user && isLabourer(userData?.user) && (
                        <Menu.Item>
                          {({ active }) => (
                            <div className="relative">
                              <a
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                onMouseEnter={() => {
                                  setSubMenuOpen(true);
                                }}
                                onMouseLeave={() => {
                                  setSubMenuOpen(false);
                                }}
                                onClick={() => {
                                  setSubMenuOpen(!subMenuOpen);
                                }}
                              >
                                <div className="flex gap-4">
                                  <p className="text-left">
                                    {t("Set Availability")}
                                  </p>
                                  <ChevronRightIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                  />
                                </div>
                              </a>
                              {subMenuOpen && (
                                <div
                                  className="absolute w-[100%] rounded border shadow-lg top-1  bg-white py-2 -left-[100%]"
                                  onMouseEnter={() => {
                                    setSubMenuOpen(true);
                                  }}
                                  onMouseLeave={() => {
                                    setSubMenuOpen(false);
                                  }}
                                >
                                  {availabilityOptions.map((option) => {
                                    return (
                                      <a
                                        key={option.id}
                                        className={classNames(
                                          "block px-4 text-left py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        )}
                                        onClick={() => {
                                          handleSubMenuClick(option.id);
                                          setSubMenuOpen(false);
                                        }}
                                      >
                                        <div className="flex justify-between gap-4">
                                          <p className="text-left">
                                            {option.title}
                                          </p>

                                          {userStatus?.status == option.id && (
                                            <CheckIcon
                                              className="w-5 h-5"
                                              aria-hidden="true"
                                            />
                                          )}
                                        </div>
                                      </a>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                      )}
                      {userData && isEmployer(userData?.user) && (
                        <Menu.Item>
                          {({ active }) =>
                            MenuItem(t("Become a labourer"), active, () => {
                              setemployeeaslabourer(!employeeaslabourer);
                              navigate("/register", {
                                state: {
                                  register: userData?.user?.phoneNumber,
                                  name: userData?.user?.name,
                                  labourer: employeeaslabourer,
                                },
                              });
                            })
                          }
                        </Menu.Item>
                      )}

                      <Menu.Item>
                        {({ active }) =>
                          MenuItem(t("Dashboard"), active, () => {
                            navigate("/dashboard");
                          })
                        }
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) =>
                          MenuItem(t("Profile"), active, () => {
                            navigate("/profile");
                          })
                        }
                      </Menu.Item>

                      {userData &&
                        hasRole(userData?.user, UserRole.LABOURER) && (
                          <Menu.Item>
                            {({ active }) =>
                              MenuItem(t("Subscribe"), active, () => {
                                navigate("/subscribe");
                              })
                            }
                          </Menu.Item>
                        )}

                      <div className="h-0.5 bg-gray-100"></div>
                      <Menu.Item>
                        {({ active }) =>
                          MenuItem(t("Log Out"), active, () => {
                            setlogoutOpen(!logoutOpen);
                          })
                        }
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <>
                  <div className="lg:flex hidden items-center justify-center gap-[32px] relative"></div>
                  <div className="lg:flex hidden items-center justify-center gap-[32px] relative">
                    <button
                      onClick={() => {
                        resetPreselectedUserType();
                        setOpen(true);
                      }}
                      className="flex items-center justify-center 4xl:text-[1vw] text-[15px] leading-[13px] font-medium 4xl:py-[23px] py-[13px] px-[16px] rounded-full bg-[#F0A61F]"
                    >
                      {t("SignUp/Login")}{" "}
                      <AiFillCaretRight className="4xl:text-[1vw] text-[12px]" />
                    </button>
                  </div>
                </>
              )}

              {open && (
                <Login
                  open={open}
                  setOpen={setOpen}
                  lang={lang}
                  preSelectedUserType={preSelectedUserType}
                />
              )}
            </div>

            <div
              className="sm:ml-[15px] block lg:hidden cursor-pointer"
              onClick={() => setActive(!active)}
            >
              <RiMenu3Fill className="sm:text-[30px] text-[20px]" />
            </div>
          </div>
        </div>
        {active && (
          <div className="lg:hidden block absolute p-[15px] top-[calc(100%+10px)] rounded-md bg-[#fff] w-[calc(100%-40px)] left-[50%] translate-x-[-50%] shadow-lg z-[10]">
            <ul className="flex flex-col gap-[5px]">
              {nav.map((val, i) => {
                return (
                  <li key={i} className="rounded-md">
                    <button
                      onClick={() => {
                        setActive(false);
                        navigate("/");
                      }}
                      className={`${
                        getPath.pathname === val.route
                          ? "bg-[#FFB800] text-[#fff]"
                          : "text-[#272D29]"
                      } font-bold py-[7px] hover:bg-[#FFB800] rounded-md hover:text-[#fff] flex text-center justify-center`}
                    >
                      {val.name}
                    </button>
                  </li>
                );
              })}
            </ul>
            <div className="flex items-center justify-center gap-[32px] mt-3">
              <button
                onClick={() => {
                  setOpen(true);
                  //FIX : Login FIX
                  //setLogin(true);
                }}
                className="flex items-center justify-center text-[15px] leading-[13px] font-medium py-[13px] px-[16px] rounded-full bg-[#F0A61F]"
              >
                {t("SignUp/Login")} <AiFillCaretRight className="text-[12px]" />
              </button>
            </div>
          </div>
        )}
      </div>
      <Fragment>
        <Dialog
          open={logoutOpen}
          handler={() => setlogoutOpen(true)}
          className="p-[20px] lg:max-w-[450px] min-w-[unset] w-full mx-auto"
        >
          <>
            <div className="mt-5">
              <h1 className="text-[24px] text-black font-bold text-center mt-4 mb-10">
                Are you sure want to logout ?
              </h1>
              <div className="flex gap-4 max-w-[300px] mx-auto">
                <button
                  onClick={handleLogOut}
                  className="flex-1 py-2 bg-[#272B2A] text-white font-semibold rounded-lg text-lg"
                >
                  Yes
                </button>
                <button
                  onClick={() => setlogoutOpen(false)}
                  className="flex-1 py-2 bg-[#272B2A] text-white font-semibold rounded-lg text-lg"
                >
                  No
                </button>
              </div>

              <div>
                <button
                  className="absolute right-[12px] text-black top-[12px] "
                  onClick={(e) => {
                    setlogoutOpen(!logoutOpen);
                  }}
                >
                  <XMarkIcon className="h-7 w-7" aria-hidden="true" />
                </button>
              </div>
            </div>
          </>
        </Dialog>
      </Fragment>
    </>
  );
};
export default Navbar;
