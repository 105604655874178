import { Autocomplete, Stack, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchLabourer, useServiceCategoryQuery } from "../../API/api";
import { Service } from "../../API/types";

interface AutocompleteServicesProps {
  name: string;
  serviceData: Service[];
  selectedService: (service: Service[]) => void;
}

const AutocompleteServices = (props: AutocompleteServicesProps) => {
  const data = props.serviceData || [];

  const [filteredOptions, setFilteredOptions] = useState<Service[]>([]);
  const [selectedOption, setSelectedOption] = useState(false);
  const [dataId, setDataId] = useState<Service[]>([]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const filtered = data.filter((option: Service) =>
      option.name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  return (
    <Stack>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        disableClearable
        options={filteredOptions}
        getOptionLabel={(option: any) => option.name}
        onChange={(event, value: any) => {
          setSelectedOption(value !== null);
          setDataId(value !== null ? [value] : []);
          //console.log('value', value)
          props.selectedService([value]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            placeholder={props?.name}
            variant="outlined"
            onChange={handleInputChange}
            defaultValue={props?.name ?? ""}
          />
        )}
      />
    </Stack>
  );
};

export default AutocompleteServices;
