import * as yup from "yup";
import {
  AADHAR_NUMBER_REQUIRED,
  NAME_REQUIRED,
  PHONE_REQUIRED,
  INVALID_AADHAR_FORMAT,
  INVALID_PHONE_FORMAT,
  INVALID_NAME_FORMAT,
  Experience_MUST_REQUIRED,
  Experience_REQUIRED,
  SKILL_REQUIRED,
  SKILL_ID_REQUIRED,
  SKILL_NAME_REQUIRED,
  Experience_MUST_GREATER_REQUIRED,
  Experience_GREATER_REQUIRED,
  LANGUAGE_ID_REQUIRED,
  LANGUAGE_NAME_REQUIRED,
  LANGUAGE_REQUIRED,
} from "../constants/ValidationMessage";
export const LabourerRegisterSchema = yup.object({
  name: yup
    .string()
    .matches(/^[a-zA-Z\s]+$/, INVALID_NAME_FORMAT)
    .required(NAME_REQUIRED)
    .nullable(),
  phone: yup
    .string()
    .matches(/^\d{10}$/, INVALID_PHONE_FORMAT)
    .required(PHONE_REQUIRED)
    .nullable(),
  adharnumber: yup
    .string()
    .matches(/^\d{4} \d{4} \d{4}$/, INVALID_AADHAR_FORMAT)
    .required(AADHAR_NUMBER_REQUIRED)
    .nullable(),
  referralCode: yup.string().nullable(),
  selectedLanguages: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(LANGUAGE_ID_REQUIRED),
        name: yup.string().required(LANGUAGE_NAME_REQUIRED),
      })
    )
    .min(1, LANGUAGE_REQUIRED)
    .required(LANGUAGE_REQUIRED),

  skills: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(SKILL_ID_REQUIRED),
        name: yup.string().required(SKILL_NAME_REQUIRED),
        experience: yup
          .number()
          .min(1, Experience_MUST_REQUIRED)
          .required(Experience_REQUIRED)
          .test(
            Experience_GREATER_REQUIRED,
            Experience_MUST_GREATER_REQUIRED,
            (value) => value > 0
          ),
      })
    )
    .min(1, SKILL_REQUIRED)
    .required(SKILL_REQUIRED)
    .test(Experience_REQUIRED, Experience_MUST_REQUIRED, (skills) =>
      skills.every((skill) => skill.experience > 0)
    ),
});
