/**
 *  Error response
 */
export interface ErrorResponse {
  error: ApiError;
}

/**
 *  custom Error class for Api errors.
 */
export class ApiError extends Error {
  statusCode?: number;
  errorCode?: string;
  constructor(message?: string) {
    super(message);
    this.name = "ApiError";
    Object.setPrototypeOf(this, ApiError.prototype);
  }

  static copy(original: ApiError): ApiError {
    const copy = new ApiError(original.message);
    copy.name = original.name;
    copy.stack = original.stack;
    copy.statusCode = original.statusCode;
    copy.errorCode = original.errorCode;
    return copy;
  }
}

export interface VerifyOtpResponse {
  accessToken: string;
  isNewUser: boolean;
  refreshToken: string;
}

export interface VerifyOtpRequest {
  otp: string;
  verificationId: string;
  phone: string;
}

export interface VerifyMobileResponse {
  otp: string;
  verificationId: string;
}
export interface FcmTokenPayload {
  deviceId: string;
  platform: string;
  pushToken: string;
  provider: number;
}

export interface Service {
  id: number;
  name: string;
  imageUrl: string | null;
  isVisible: boolean;
  createdAt: string;
}

export interface SearchResultResponse {
  data?: SearchResult[];
  total?: number;
  offset?: number;
}

export interface SearchResult {
  id: number;
  location: string;
  lat: number;
  lng: number;
  userId: number;
  description?: null | string;
  displayImageUrl?: null;
  serviceProviderType?: null;
  distance: number;
  name?: string;
  email?: null | string;
  profileUrl?: null | string;
  phoneNumber?: string;
  skillCount?: number;
  averageStarRating: number;
  skills: SkillDto[];
  languages?: Language[];
}

export interface SkillDto {
  id?: number;
  averageStarRating?: number;
  skillId?: number;
}

export interface FavouritesDto {
  id: number;
  createdAt: string;
  labourerId: number;
  location: string;
  description: null;
  name: string;
  phoneNumber: string;
  skills: Skill[];
  languages: Language[];
  rating: number;
}

export interface Language {
  id: number;
  userId: number;
  languageId: number;
  labourerId: number;
}

export interface Skill {
  averageStarRating: number;
  experience: number;
  skillId: number;
  userId: number;
  labourerId: number;
}

export interface SignupResponse {
  user: UserDto;
  accessToken: string;
  isNewUser: boolean;
  refreshToken: string;
}

export interface UserDto {
  id: number;
  userId: string;
  name: string;
  phoneNumber: string;
  isDelete: boolean;
  tfa: boolean;
  active: boolean;
  messageToken: null;
  failedAttempt: number;
  accountLocked: boolean;
  createdAt: Date;
  profileUrl: null;
  referralCode: string;
  roles: string[];
}

export interface UserDetailResponse {
  user: UserDto;
  employerDetails: EmployerDetails;
  labourerDetails: LabourerDetails;
}

export interface EmployerDetails {
  id: number;
  userId: number;
  location: null;
  lat: null;
  lng: null;
}

export interface LabourerDetails {
  id: number;
  location: string;
  lat: number;
  lng: number;
  description: null;
  displayImageUrl: null;
  serviceProviderType: null;
  userId: number;
  skills: Skill[];
  languagesKnown: LanguagesKnown[];
  attributes: SkillAttribute[];
}

export interface SkillAttribute {
  id: number;
  skillAttributeId: number;
  skillAttributeValue: string;
  labourerId: number;
}

export interface LanguagesKnown {
  languageId: number;
  name: string;
}

export interface Skill {
  experience: number;
  skillId: number;
  name: string;
  id: number;
  text: null;
  langCode: null;
}

export enum UserType {
  EMPLOYER,
  LABOURER,
}

export enum UserRole {
  EMPLOYER = "employer",
  LABOURER = "labourer",
}

export enum Availabilities {
  AVAILABLE = 1,
  UNAVAILABLE = 2,
}

export interface UserAvailability {
  id: number;
  status: Availabilities;
  userId: number;
}

export interface AddRatingReview {
  jobAwardId?: number | null;
  review: string;
  isAnonymous: boolean;
  employerRatings: EmployerRating[];
}

export interface EmployerRating {
  id?: number | null;
  reviewId: number;
  skillId: number;
  rating: number;
}

export interface JobResponse {
  labourers: Labourer[];
  jobAwards: JobAward[];
  skill: SkillMaster[];
  employers: any[];
  total:number;
}

export interface JobAward {
  id: number;
  userId: number;
  labourerId: number;
  awardedOn: Date;
  status: number;
  jobId: number;
  job: Job;
  avgRating: number | null;
  reviewId: number | null;
}

export interface Job {
  id: number;
  key: string;
  title: string;
  location: string;
  latitude: number;
  longitude: number;
  description: string | null;
  postedOn: Date;
  status: number;
  jobSkillsRequireds: JobSkillsRequired[];
}

export interface JobSkillsRequired {
  id: number;
  jobId: number;
  skillMasterId: number;
}

export interface Labourer {
  id: number;
  name: string;
}

export interface SkillMaster {
  id: number;
  name: string;
}

export interface RatingReviewResponse {
  id: number;
  review: string;
  status: number;
  updatedAt: Date;
  createdAt: Date;
  isAnonymous: boolean;
  jobAwardId: number;
  employerRatings: EmployerRating[];
}

export interface SimilarLabourerDto {
  id: number;
  userId: number;
  name: string;
  description: string | null;
  displayImageUrl: string | null;
  distance: number;
  email: string | null;
  phoneNumber: string;
  lat: number;
  lng: number;
  location: string;
  profileUrl: string | null;
  serviceProviderType: string | null;
  skillCount: number;
  averageStarRating: number;
  languages: Language[];
  skills: Skill[];
}