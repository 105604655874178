import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import {
  employerSignUpaslabourer,
  useUpdateProfileImageMutation,
  queryClient,
  apiEndpoint,
  REACT_APP_API_URL,
  deleteSearchHistoy,
  addBankDetails,
  updateBankDetails,
  resetChatList,
  updateJobCompleted,
  BankDetailsDto,
  changeStatusToRead,
  sendFeedback,
  deleteReview,
} from "../../API/api";
import { setToken, _token } from "../../utils/jwtUtils";
import { ApiError, SignupResponse } from "../../API/types";
import * as QueryKey from "../../constants/QueryKeys";
import { updateEmployerReview } from "../../API/reviewsApi";
import {
  removeFavourite,
  AddFavoriteResponse,
  useAddFavourite,
} from "../../API/favoritesApi";

export const labourerSignUp = async (registerData: Record<string, any>) => {
  return apiEndpoint<SignupResponse>(
    `${REACT_APP_API_URL}/users/signup`,
    registerData,
    "POST",
    true
  );
};

export const useSignUpLabourerMutation = () => {
  return useMutation(labourerSignUp, {
    onSuccess: async (data) => {
      if (data.accessToken) {
        setToken(data.accessToken);
        await queryClient.invalidateQueries(['users', _token()]);
      }
    },
    onError: (error: ApiError) => {
      if (error) {
        toast.error(error?.message);
      }
    },
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries(["users", _token()]);
      }
    },
  });
};

export const useSignUpEmployerMutation = () => {
  return useMutation(employerSignUpaslabourer, {
    onSuccess: () => {},
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        await queryClient.invalidateQueries(["users", _token()]);
      }
    },
  });
};

export const useImageMutation = () => {
  return useUpdateProfileImageMutation();
};

// Delete Search History
export function useDeleteSearchHistory() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteSearchHistoy(id),
    onSuccess: () => {
      toast.success("Deleted Successfully");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: ["searchHistoryData"],
        });
      }
    },
  });
}


// RESET CHAT COUNT
export const useResetChatList = () => {
  return useMutation({
    mutationFn: (roomId: any) => resetChatList(roomId),
    onSuccess: async () => {
      queryClient.invalidateQueries([QueryKey.CHAT_COUNTLIST]);
      queryClient.invalidateQueries([QueryKey.CHAT_COUNT]);
    },
    onError: (error) => {
      toast.error("Error resetting chat list");
      console.error("Error resetting chat list:", error);
    },
  });
};

export const useAddBankDetails = () => {
  return useMutation({
    mutationFn: (bankDetails: BankDetailsDto) => addBankDetails(bankDetails),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_BANK_DETAILS],
      });
    },
    onSettled: async (_, error) => {
      if (error) {
        console.error(error);
      } else {
        toast.success("Bank details added successfully!");

        await queryClient.invalidateQueries([QueryKey.GET_BANK_DETAILS]);
      }
    },
    onError: () => {
      toast.error("Failed to add bank details");
    },
  });
};

export const useUpdateBankDetails = () => {
  return useMutation({
    mutationFn: async (bankDetails: BankDetailsDto) => {
      try {
        const response = await updateBankDetails(bankDetails);
        return response;
      } catch (error) {
        throw new Error("Failed to update bank details");
      }
    },
    onSettled: async () => {
      toast.success("Bank details updated successfully!");
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_BANK_DETAILS],
      });
    },
    onError: (error: any) => {
      console.error("Error updating bank details:", error);
      toast.error("Failed to update bank details");
    },
  });
};

export const useUpdateJobCompleted = () => {
  return useMutation({
    mutationFn: async (jobData: any) => {
      try {
        const response = await updateJobCompleted(jobData);
        return response;
      } catch (error) {
        throw new Error("Failed to update JobCompleted");
      }
    },
    onSettled: async () => {
      toast.success("JobCompleted updated successfully!");
    },
    onError: (error: any) => {
      console.error("Error JobCompleted:", error);
      toast.error("Failed to update JobCompleted");
    },
  });
};

export const useMarkAllAsRead = () => {
  return useMutation({
    mutationFn: async (ids: number[]) => {
      try {
        const response = await changeStatusToRead(ids);
        return response;
      } catch (error) {
        throw new Error("Failed to mark notifications as read");
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_UNREAD_NOTIFICATIONS],
      });
    },
    onSettled: async () => {
      toast.success("All notifications marked as read!");
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_UNREAD_NOTIFICATIONS],
      });
    },
    onError: (error: any) => {
      console.error("Error marking notifications as read:", error);
      toast.error("Failed to update notifications");
    },
  });
};

export const useSendFeedback = () => {
  return useMutation({
    mutationFn: (feedbackDetails: any) => sendFeedback(feedbackDetails),
    onSuccess: async () => {
      toast.success("Feedback submitted successfully!");
    },
    onError: (error) => {
      console.error(error);
      toast.error("Failed to submit feedback");
    },
  });
};

// DELETE REVIEW
export function useDeleteReview() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => deleteReview(id),
    onSuccess: () => {
      toast.success("Deleted Successfully");
    },
    onSettled: async (_, error) => {
      if (error) {
        console.log(error);
      } else {
        await queryClient.invalidateQueries({
          queryKey: [QueryKey.GET_EMPLOYER_REVIEW],
        });
      }
    },
  });
}

// UPDATE REVIEW
export const useUpdateReview = () => {
  return useMutation({
    mutationFn: async (reviewData: any) => {
      try {
        const response = await updateEmployerReview(reviewData);
        return response;
      } catch (error) {
        throw new Error("Failed to update review and rate");
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_EMPLOYER_REVIEW],
      });
      toast.success("review and rate updated successfully!");
    },
    onError: (error: any) => {
      console.error("Error updating review:", error);
      toast.error("Failed to update review and rate");
    },
  });
};

// DELETE FAVOURITE
export function useRemoveFavouriteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      await removeFavourite(id);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKey.GET_FAVOURITES],
      });
      toast.success("Favourite removed successfully");
    },
    onError: (error: Error) => {
      console.error("Failed to delete favourite:", error);
      toast.error("Failed to delete favourite");
    },
  });
}

export const useAddFavouriteMutation = () => {
  const onSuccessCb = (data: AddFavoriteResponse) => {
    toast.success("Added as favourites.");
  };

  const onErrorCb = (error: ApiError) => {
    toast.error(error.message);
  };

  return useAddFavourite({ onSuccessCb, onErrorCb });
};
