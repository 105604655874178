import React from "react";
import Slider from "react-slick";
import { Settings } from "react-slick";
import { HeartIcon, StarIcon } from "@heroicons/react/20/solid";
import { useCompleteImageUrl } from "../../utils/GeneralUtils";
import { useNavigate } from "react-router-dom";
import { getSkillName } from "../../utils/utils";
import { FavouritesDto } from "../../API/types";

interface SkillDto {
  id: string;
  name: string;
}

interface Labourer {
  id: string;
  name: string;
  profileUrl: string | null;
  rating: number;
  reviewsCount: number;
  skills: SkillDto[];
  location: string;
  distance: number;
  isFavorite: boolean;
}

interface SimilarLabourerSliderProps {
  allResult: Labourer[];
  t: (key: string) => string;
  skill: any;
  favLabourerId: number;
  favouritesData: FavouritesDto[];
  serviceData: any;
}

const SimilarLabourerSlider: React.FC<SimilarLabourerSliderProps> = ({
  allResult,
  t,
  skill,
  favLabourerId,
  favouritesData,
  serviceData,
}) => {
  const getCompleteImageUrl = useCompleteImageUrl();

  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();
  return (
    <div className=" detailslider w-full px-4 ">
      <Slider {...settings}>
        {allResult
          ?.filter((labourer: any) => labourer.id !== favLabourerId)
          ?.map((labourer: any) => {
            const isFavorite = favouritesData?.find(
              (favorite: FavouritesDto) => favorite?.labourerId === labourer?.id
            );
            return (
              <div key={labourer?.id} className="px-2">
                <div
                  className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigate("/details", {
                      state: {
                        details: labourer,
                        favLabourerId: labourer?.id,
                        alldata: allResult,
                      },
                    });
                  }}
                >
                  <div className="relative">
                    <div className="p-2 flex justify-center ">
                      {labourer?.profileUrl ? (
                        <img
                          src={getCompleteImageUrl(labourer?.profileUrl || "")}
                          alt={labourer?.name}
                          className="w-full h-40 object-cover "
                        />
                      ) : (
                        <img
                          src={require("../../assets/images/profile.png")}
                          alt={labourer?.name}
                          className="w-40 h-40 object-cover justify-center"
                        />
                      )}
                    </div>
                    <button className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition-colors duration-300">
                      <HeartIcon
                        className={`w-5 h-5 ${
                          isFavorite
                            ? "text-red-500 fill-current"
                            : "text-gray-400"
                        }`}
                      />
                    </button>
                  </div>
                  <div className="p-4">
                    <div className="flex gap-3 items-center mb-2">
                      <h2 className="text-xl font-semibold text-gray-800 ">
                        {t(labourer?.name)}
                      </h2>
                      <div
                        className={`bg-[#eb300a]
                         4xl:w-[20px] 4xl:h-[20px] w-[17px] h-[17px]  rounded-full   top-[210px] right-2 md:top-1 md:left-[194px]`}
                      />
                    </div>

                    <div className="flex items-center mb-2">
                      {[...Array(5)].map((_, i) => (
                        <StarIcon
                          key={i}
                          className={`w-5 h-5 ${
                            i < labourer?.rating
                              ? "text-yellow-400"
                              : "text-gray-300"
                          }`}
                        />
                      ))}
                      <span className="ml-2 text-sm text-gray-600">
                        {labourer?.reviewsCount} {t("reviews")}
                      </span>
                    </div>
                    <div className="flex flex-wrap gap-2 mb-2">
                      <span className="bg-blue-100 text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded">
                        {t(
                          `${getSkillName(
                            labourer?.skills[0]?.skillId,
                            serviceData
                          )} (${labourer?.skills[0]?.experience} ${t("years")})`
                        )}
                      </span>
                    </div>
                    <p className="text-sm text-gray-600">
                      {t(labourer?.location)} ({labourer?.distance?.toFixed(2)}{" "}
                      {t("kms")})
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default SimilarLabourerSlider;
