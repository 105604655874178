import React, { useEffect, useRef, useState } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
} from "@mui/material/Autocomplete";
import { Downarrow } from "../../assets/images/AllSvg";
import Checkbox from "@mui/material/Checkbox";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  useLanguagesQuery,
  useUpdateProfileImageMutation,useUserMeQuery
} from "../../API/api";
import MyAutocomplete from "../../component/AutoCompleteSkills/AutoComplete";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { UploadProfilePic } from "../../component/Models/UploadProfilePic";
import { LabourerRegisterSchema } from "../../schema/labourerSignInSchema";
import {
  useSignUpLabourerMutation,
  useSignUpEmployerMutation,
} from "../services/Mutation";
import { dataURLtoBlob, getFileTypeFromUrl } from "../../utils/utils";
import { UserRole } from "../../API/types";
import useGeolocation, {
  AddressDetails,
  LocationData,
} from "../../utils/useGeolocation";
import LocationSelector from "../../pages/Location/LocationSelector";
import { useLocationContext } from "../Context/LocationContext";

interface LabourerRegisterFormState {
  register?: RegisterData;
  name?: string;
  referralCode?: string;
  labourer?: boolean;
}
interface RegisterData {
  phone: any;
  verificationId: string;
}
interface Language {
  id: number;
  name: string;
}
interface LabourRegisterFormProps {
  registerData: any;
  setRegisterData: React.Dispatch<React.SetStateAction<any>>;
  handleSkillUpdate: (updatedSkills: any) => void;
  nextStep: () => void;
}
function LabourRegisterForm({
  registerData,
  setRegisterData,
  handleSkillUpdate,
  nextStep,
}: LabourRegisterFormProps) {
  const location = useLocation();
  const {
    register: regData,
    name: regName,
    referralCode: regRefCode,
    labourer: regLabourer,
  } = location?.state as LabourerRegisterFormState;

  const [selectedLocation, setSelectedLocation] =
    useState<null | AddressDetails>({
      city: "Ahmedabad",
      state: "Gujarat",
      country: "India",
    });

  const { state } = useLocationContext();
  const { selectedLocationData } = state;
  const { refetch:refetchUser } = useUserMeQuery();
  // console.log("geoLocation 456",selectedName)
  const employeraslabourer = regLabourer === false ? true : regLabourer;
  const { t } = useTranslation();
  const languagesQuery = useLanguagesQuery();
  const languagesData = languagesQuery.data || [];
  const [selectedLanguages, setSelectedLanguages] = useState<number[]>([]);
  const [showUploadPicModal, setShowUploadPicModal] = useState<boolean>(false);
  const [imageErrorStatus, setImageErrorStatus] = useState<boolean>(false);

  const [url, setUrl] = useState("");
  const {
    register: registerForm,
    handleSubmit: registerFormSubmission,
    formState: { errors: registerFormErrors },
    reset: registerFormReset,
    control: registerFormControl,
  } = useForm({
    resolver: yupResolver(LabourerRegisterSchema),
    mode: "onBlur",
  });

  const handleSelectedLanguagesChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: { id: number; name: string }[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => {
    const selectedLanguageIds = value.map((language) => language.id);
    setSelectedLanguages(selectedLanguageIds);
  };

  // mutation
  const SignUpLabourerMutation = useSignUpLabourerMutation();
  const SignUpEmployerMutation = useSignUpEmployerMutation();
  const ImageMutation = useUpdateProfileImageMutation();

  const capture = async (imageSrc: string) => {
    if (imageSrc) {
      setUrl(imageSrc);
      setShowUploadPicModal(false);
    }
  };

  const handleRegister = async (formData: any) => {
    try {
      if (!selectedLocationData?.lat || !selectedLocationData?.lng) {
        toast.error("Please select a valid location.");
        return;
      }
      if (!url) {
        toast.error("Please upload your profile image.");
        return;
      }
      const { adharnumber, referralCode, ...formDataWithoutAadhaar } = formData;
      const updatedFormData = {
        ...formDataWithoutAadhaar,
        languagesKnown: selectedLanguages,
        userType: UserRole.LABOURER,
        lat: selectedLocationData.lat,
        lng: selectedLocationData.lng,
        location: selectedLocationData.city,
        referralCode: referralCode,
        skills: registerData?.skills,
      };
      const mutation = employeraslabourer
        ? SignUpEmployerMutation
        : SignUpLabourerMutation;
      try {
        if (!imageErrorStatus) {
          await mutation.mutateAsync(updatedFormData);
        }
        await doImageUpload();
      } catch (error) {
        console.error("Error occurred during mutation:", error);
        toast.error("An error occurred during registration. Please try again.");
      }
    } catch (error) {
      console.error("Error during form submission:", error);
      toast.error(
        "An error occurred during form submission. Please try again."
      );
    }
  };

  const doImageUpload = async () => {
    try {
      if (!url) {
        toast.error("No image URL provided.");
      }
      const blob = dataURLtoBlob(url);
      let fileType = getFileTypeFromUrl(url);
      const file = new File([blob], "image." + fileType.split("/")[1], {
        type: fileType,
        lastModified: Date.now(),
      });
      const imageResponse = await ImageMutation.mutateAsync(file);
      if (imageResponse) {
        toast.success("Successfully registered as a labourer");
        await refetchUser()
        nextStep();
        setShowUploadPicModal(false);
        registerFormReset();
      }
    } catch (error) {
      console.error("Error occurred during image upload:", error);
      toast.error("Failed to upload profile image.");
      setImageErrorStatus(true);
    }
  };

  const handleProfilePicModalOpen = () => {
    setShowUploadPicModal((value) => !value);
  };

  return (
    <>
      <form onSubmit={registerFormSubmission(handleRegister)}>
        <div className="max-w-[857px] w-full mt-[45px] sm:px-5 px-3 mx-auto p-10  border border-gray-300 shadow-lg rounded ">
          <div className="flex gap-[39px] w-full md:flex-row flex-col">
            <div>
              <div className="bg-[#FBE9C7] min-w-[147px] h-[147px] w-max mx-auto flex justify-center items-center rounded-full">
                <div className="w-[150px] h-[150px] rounded-full overflow-hidden">
                  <img
                    src={url ? url : require("../../assets/images/profile.png")}
                    alt=""
                    className="object-cover h-full"
                  />
                </div>
              </div>
              <button
                type="button"
                className="bg-[#FBE9C7]  py-1.5 px-10 mx-auto mt-3 flex justify-center items-center"
                onClick={handleProfilePicModalOpen}
              >
                <AiOutlineCamera className="text-[#F0A61F] text-[26px]" />
              </button>
              {showUploadPicModal && (
                <UploadProfilePic
                  open={showUploadPicModal}
                  handleClose={handleProfilePicModalOpen}
                  handleImageSave={capture}
                  isApiCalling={ImageMutation.isLoading}
                  isError={ImageMutation.isError}
                  error={
                    ImageMutation.error as {
                      statusCode: number;
                      name: string;
                      message: string;
                    }
                  }
                />
              )}
            </div>
            <div className="w-full flex flex-col gap-[23px]">
              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Name")}
                </label>
                <div className="w-full">
                  <input
                    type="text"
                    value={regData ? regName : regName}
                    className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                    placeholder="Enter your name"
                    {...registerForm("name")}
                  />
                  <p className="text-[16px] text-red-600">
                    {registerFormErrors.name?.message}
                  </p>
                </div>
              </div>
              <div className="flex sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] sm:mt-3 font-medium min-w-[167px]">
                  {t("MobileNumber")}
                </label>
                <div className="w-full">
                  <input
                    type="number"
                    value={regData?.phone ? regData?.phone : regData}
                    className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                    placeholder="9021997888"
                    {...registerForm("phone")}
                  />
                  <p className="text-[16px] text-red-600">
                    {registerFormErrors.phone?.message}
                  </p>
                </div>
              </div>

              <div className="flex sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] sm:mt-3 font-medium min-w-[167px]">
                  {t("Aadhaar Number")}
                </label>
                <div className="w-full">
                  <div className="relative">
                    <input
                      type="text"
                      className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                      placeholder="Enter your Aadhaar Number"
                      {...registerForm("adharnumber")}
                    />
                  </div>
                  <div className="text-[16px] text-red-600">
                    {registerFormErrors.adharnumber?.message}
                  </div>
                </div>
              </div>

              <div>
                <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                  <label className="text-[17px] font-medium min-w-[167px]">
                    {t("Languages Spoken")}
                  </label>

                  <div className="relative w-full ">
                    {languagesData && (
                      <Controller
                        name="selectedLanguages"
                        control={registerFormControl}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            multiple
                            id="checkboxes-tags-demo"
                            options={languagesData as readonly Language[]}
                            disableCloseOnSelect
                            getOptionLabel={(option: Language) => option.name}
                            onChange={(event, value, reason, details) => {
                              field.onChange(value);
                              handleSelectedLanguagesChange(
                                event,
                                value,
                                reason,
                                details
                              );
                            }}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  sx={{
                                    color: "#D4D4D4",
                                    "&.Mui-checked": {
                                      color: "#F0A61F",
                                    },
                                    "& .MuiSvgIcon-root": { fontSize: 25 },
                                  }}
                                  size="small"
                                  checked={selected}
                                />
                                {option.name}
                              </li>
                            )}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Search Languages"
                                error={!!registerFormErrors.selectedLanguages}
                              />
                            )}
                          />
                        )}
                      />
                    )}
                    <div className="absolute top-[50%] translate-y-[-50%] right-[20px]">
                      <Downarrow />
                    </div>
                    <div className="text-[16px] text-red-600 mt-2">
                      {registerFormErrors.selectedLanguages &&
                        registerFormErrors.selectedLanguages.message}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full select-main">
                  <label className="text-[17px] font-medium min-w-[167px]">
                    {t("Skills with Experiences")}
                  </label>

                  <div className="relative w-full">
                    <MyAutocomplete
                      control={registerFormControl}
                      handleSkillUpdate={handleSkillUpdate}
                      error={registerFormErrors.skills?.message}
                    />
                  </div>
                </div>
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Location")}
                </label>

                {/* <div className="flex gap-[14px] md:flex-nowrap flex-wrap w-full rounded  items-center px-3 border-[#B9B9B9] border-[1px]"> */}
                  {/* <div className="flex md:gap-[14px] justify-between w-full md:w-auto"> */}
                    <LocationSelector />
                  {/* </div> */}
                {/* </div> */}
              </div>

              <div className="flex sm:items-center sm:flex-row flex-col sm:gap-[33px] gap-[10px] w-full">
                <label className="text-[17px] font-medium min-w-[167px]">
                  {t("Referral Code")}
                </label>

                <div className="w-full">
                  <input
                    type="text"
                    value={regRefCode}
                    className="border-[#D1D0CA] border-[1px] rounded h-[48px] px-[12px] w-full"
                    {...registerForm("referralCode")}
                  />
                </div>
              </div>

              <button
                type="submit"
                disabled={
                  SignUpLabourerMutation.isLoading ||
                  SignUpEmployerMutation.isLoading ||
                  ImageMutation.isLoading
                }
                className={`text-white md:w-[258px] md:h-[44px] w-[150px]  h-[44px] sm:ml-[13.125rem]  mx-auto mt-11 rounded font-roboto font-medium text-lg leading-6 ${
                  SignUpLabourerMutation.isLoading ||
                  SignUpEmployerMutation.isLoading ||
                  ImageMutation.isLoading
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-[#272D29]"
                }`}
              >
                {SignUpLabourerMutation.isLoading ||
                SignUpEmployerMutation.isLoading ||
                ImageMutation.isLoading ? (
                  <span>Loading...</span>
                ) : (
                  <span>{t("Next")}</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default LabourRegisterForm;
