import React from "react";
import { FaUserTie } from "react-icons/fa";
import { t } from "i18next";

interface BecomeLabourerModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}
const BecomeLabourerModal: React.FC<BecomeLabourerModalProps> = ({
  onConfirm,
  onCancel,
}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-2 rounded-xl shadow-2xl max-w-md w-full m-4 transition-all duration-300 ease-in-out transform ">
        <div className="flex flex-col items-center text-center">
          <div className="bg-blue-100 p-3 rounded-full mb-4">
            <FaUserTie className="text-blue-600 text-4xl" />
          </div>
          <h2 className="text-2xl font-bold text-gray-800 mb-2">
            {t("Become an Employer")}
          </h2>
          <p className="text-lg text-gray-600 mb-6">
            
            {t("You wish to become an employer (Service Seeker) too?")}
          </p>
        </div>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onCancel}
            className="px-6 py-3 bg-gray-200 text-gray-800 rounded-lg hover:bg-gray-300 transition-colors duration-300 font-medium"
          >
            {t("No, Thanks")}
          </button>
          <button
            onClick={onConfirm}
            className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-300 font-medium"
          >
            {t("Yes, I'm In!")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BecomeLabourerModal;
