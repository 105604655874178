import React from "react";
import { StarIcon } from "@heroicons/react/20/solid";
import { DeleteIcon, EditIcon, Review1 } from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { classNames } from "../../utils/utils";
import { useReviewDetails } from "../../API/reviewsApi";

interface EmployerRating {
  rating: number;
  reviewId: number;
  skillId: number;
}

interface Review {
  reviewId: number;
  review: string;
  isAnonymous: string;
  employerRatings: EmployerRating[];
  labourerId: number;
  title: string;
}

interface Labourer {
  id: number;
  name: string;
}

interface ReviewListProps {
  reviews: Review[];
  labourers: Labourer[];
  onEdit: (review: Review) => void;
  onDelete: (reviewId: number) => void;
}

const ReviewList: React.FC<ReviewListProps> = ({
  reviews,
  labourers,
  onEdit,
  onDelete,
}) => {
  const { t } = useTranslation();
  // const { data: reviewDetail, isLoading, refetch } = useReviewDetails(137);

  return (
    <div className="border border-[#D1D0CA]">
      {reviews?.map((review) => {
        // Match the labourer based on labourerId
        const matchedLabourer = labourers?.find(
          (labourer) => labourer?.id === review?.labourerId
        );

        if (matchedLabourer) {
          return (
            <div
              key={review.reviewId}
              className="border-b border-[#D1D0CA] p-3"
            >
              <div className="2xl:pr-[53px] px-3 2xl:pl-[35px] p-3 pb-4 flex flex-wrap justify-between items-center">
                <div className="flex min-w-[130px] justify-between items-center flex-col">
                  <Review1 />
                  <h3 className="text-lg font-semibold text-[#222222]">
                    {matchedLabourer?.name}
                  </h3>
                </div>
                <h2 className="text-[20px] text-center font-semibold text-[#222222]">
                  {t(review?.title)}
                </h2>
                <p className="text-base text-center max-w-[376px] w-full font-medium text-[#222222]">
                  {review?.review}
                </p>
                {/* <div className="flex star">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <StarIcon
                      key={`${review?.reviewId}_${rating}`}
                      className={classNames(
                        review?.employerRatings[0]?.rating > rating
                          ? "text-[#F0A61F]"
                          : "text-gray-200",
                        "h-5 w-5 flex-shrink-0"
                      )}
                      aria-hidden="true"
                    />
                  ))}
                </div> */}
                <div className="flex items-center gap-5">
                  <button
                    onClick={() => onEdit(review)}
                    aria-label={t("Edit review") as string}
                  >
                    <EditIcon />
                  </button>
                  <button
                    onClick={() => onDelete(review?.reviewId)}
                    aria-label={t("Delete review") as string}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default ReviewList;
