import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import { Taxi } from "../../assets/images/AllSvg";
import { Service } from "../../API/types";
import { useCompleteImageUrl } from "../../utils/GeneralUtils";

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  arrows: true,
  //slidesToShow: 5,
  slidesToScroll: 1,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        // slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1224,
      settings: {
        // slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        // slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        // slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
      },
    },
    {
      breakpoint: 480,
      settings: {
        // slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

interface Props {
  services: Service[] | undefined;
  searchInput: string;
  onServiceCategorySelected: (category: Service) => void;
}

const OurServices: React.FC<Props> = ({
  services,
  searchInput,
  onServiceCategorySelected,
}) => {
  const { t } = useTranslation();

  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();

  const ImageWithText = (item: Service) => {
    return (
      <div className="flex flex-col items-center justify-center inline-block p-4 rounded-lg drop-shadow-md">
        <img
          src={
            item?.imageUrl
              ? getCompleteImageUrl(item.imageUrl)
              : require("../../assets/images/no_preview_image.png")
          }
          className="w-24 h-24 mb-4 rounded-lg shadow-sm"
          alt="Preview"
        />

        <p className="text-base  truncate">{item?.name}</p>
      </div>
    );
  };

  return (
    <div className="bg-[#fff] py-[20px] mt-10 shadow-md rounded-lg">
      <h2 className="text-[#272D29] text-left pl-2 font-bold 2xl:text-[24px] lg:text-[20px] text-[16px] leading-[78px] mb-2.5">
        {t("OurServices")}
      </h2>
      <div className="w-full mr-0 detailslider Service">
        <Slider {...settings}>
          {services
            ?.filter((item: Service) =>
              item.name.toLowerCase().includes(searchInput.toLowerCase())
            )
            ?.map((data: Service, index: number) => {
              return (
                <div
                  key={index}
                  onClick={() => onServiceCategorySelected(data)}
                  className="cursor-pointer "
                >
                  {ImageWithText(data)}
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default OurServices;
