import React, { useState, useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { useServiceCategoryQuery } from "../../API/api";

interface Skill {
  id: number;
  name: string;
  experience: number;
}

interface MyAutocompleteProps {
  control: Control<any>;
  handleSkillUpdate: (skills: { id: number; experience: number }[]) => void;
  error?: string;
}

const MyAutocomplete: React.FC<MyAutocompleteProps> = ({
  control,
  handleSkillUpdate,
  error,
}) => {
  const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
  const [showExperience, setShowExperience] = useState<boolean>(false);

  const servicesQuery = useServiceCategoryQuery();
  const data = servicesQuery.data || [];
  useEffect(() => {
    handleSkillUpdate(
      selectedSkills.map((skill) => ({
        id: skill.id,
        experience: skill.experience,
      }))
    );
  }, [selectedSkills, handleSkillUpdate]);

  return (
    <Controller
      name="skills"
      control={control}
      render={({ field }) => (
        <div className="relative">
          <Autocomplete
            multiple
            options={data as any[]}
            value={selectedSkills}
            onChange={(event, newValue) => {
              const updatedSkills = newValue.map((skill) => ({
                ...skill,
                experience:
                  selectedSkills.find((s) => s.id === skill.id)?.experience ||
                  0,
              }));
              setSelectedSkills(updatedSkills);
              field.onChange(updatedSkills);
              setShowExperience(updatedSkills.length > 0);
            }}
            getOptionLabel={(option: Skill) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  sx={{
                    color: "#D4D4D4",
                    "&.Mui-checked": { color: "#F0A61F" },
                    "& .MuiSvgIcon-root": { fontSize: 25 },
                  }}
                  size="small"
                  checked={selected}
                />
                {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} placeholder="Skills" error={!!error} />
            )}
          />
          {error && <p className="text-red-600 text-md mt-2">{error}</p>}
          {selectedSkills.length > 0 && showExperience && (
            <div className="absolute top-full mt-2 w-full bg-white p-4 rounded-lg shadow-md border z-10">
            <div className="max-h-[280px] overflow-y-auto no-scrollbar">
              {selectedSkills.map((skill) => (
                <React.Fragment key={skill.id}>
                  <div className="mb-4 p-2 border rounded">
                    <div className="flex items-center ">
                      <Checkbox
                        sx={{
                          color: "#D4D4D4",
                          "&.Mui-checked": { color: "#F0A61F" },
                          "& .MuiSvgIcon-root": { fontSize: 25 },
                        }}
                        size="small"
                        checked={true}
                      />
                      <span className="ml-2 font-medium">{skill?.name}</span>
                    </div>
                    <div className="flex items-center ml-8">
                      <span className="mr-2 text-sm text-gray-600">
                        Experience:
                      </span>
                      <TextField
                        sx={{
                          padding: "0",
                          ":focus": { outline: "none", border: "none" },
                          height: "28px",
                          width: "60px",
                          boxSizing: "border-box",
                          textAlign: "center",
                          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                            borderRadius: "4px",
                          },
                        }}
                        className="focus:ring-0 focus:outline-none focus:border-none focus:ring-offset-shadow-none focus:ring-offset-0"
                        value={skill.experience || ""}
                        onChange={(e) => {
                          const updatedSkills = selectedSkills.map((s) =>
                            s.id === skill.id
                              ? { ...s, experience: Number(e.target.value) }
                              : s
                          );
                          setSelectedSkills(updatedSkills);
                          field.onChange(updatedSkills);
                        }}
                        type="number"
                        error={skill.experience <= 0}
                      />
                      <span className="ml-1 text-sm text-gray-600">Years</span>
                    </div>
                    {skill.experience <= 0 && (
                      <div className="text-red-600 text-xs mt-1 ml-8">
                        Enter {skill?.name} experience in years
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
            </div>
            <button
              className="bg-[#272D29] text-white font-bold rounded px-4 py-2 mt-4 w-full"
              onClick={() => setShowExperience(false)}
            >
              Save
            </button>
          </div>
          )}
        </div>
      )}
    />
  );
};

export default MyAutocomplete;
