import React, { useReducer, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useEmployerReviews } from "../../API/reviewsApi";
import {
  useUpdateReview,
  useDeleteReview,
} from "../../component/services/Mutation";
import EditReviewModal from "./EditReviewModal";
import ReviewList from "./ReviewList";
import { toast } from "react-toastify";
import ConfirmationModal from "../DeleteModal";
import { RiLoader2Fill } from "react-icons/ri";
import { FiAlertCircle } from "react-icons/fi";

// TYPES
interface EmployerRating {
  rating: number;
  reviewId: number;
  skillId: number;
}

interface Review {
  reviewId: number;
  review: string;
  isAnonymous: string;
  employerRatings: EmployerRating[];
  labourerId: number;
}
interface AddRatingReview {
  review: string;
  isAnonymous: boolean;
  employerRatings: EmployerRating[];
}

// CONSTANTS
const DEFAULT_SKILL_ID = 1;
const INITIAL_STATE = {
  reviewOpen: false,
  editingReview: null,
  editedRating: 0,
  editedReviewText: "",
  matchedLabourer: null,
  deleteItem: null,
  isConfirmationOpen: false,
};

// REDUCER
function reviewReducer(state: any, action: any) {
  switch (action.type) {
    case "OPEN_REVIEW":
      return { ...state, reviewOpen: true, ...action.payload };
    case "CLOSE_REVIEW":
      return {
        ...state,
        reviewOpen: false,
        editingReview: null,
        matchedLabourer: null,
      };
    case "SET_EDITED_RATING":
      return { ...state, editedRating: action.payload };
    case "SET_EDITED_REVIEW_TEXT":
      return { ...state, editedReviewText: action.payload };
    case "SET_DELETE_ITEM":
      return { ...state, deleteItem: action.payload, isConfirmationOpen: true };
    case "CLOSE_CONFIRMATION":
      return { ...state, isConfirmationOpen: false, deleteItem: null };
    default:
      return state;
  }
}

const ReviewMade: React.FC = () => {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reviewReducer, INITIAL_STATE);
  const {
    data: EmployerReviewMade,
    refetch,
    isLoading,
    error,
  } = useEmployerReviews(true);
  console.log(EmployerReviewMade);
  const updateReviewMutation = useUpdateReview();
  const deleteReviewMutation = useDeleteReview();

  const handleOpenReview = useCallback(
    (item: Review) => {
      const labourerId = item.labourerId;
      const foundLabourer = EmployerReviewMade?.labourers.find(
        (labourer: any) => labourer.id === labourerId
      );

      dispatch({
        type: "OPEN_REVIEW",
        payload: {
          editingReview: item,
          editedRating: item.employerRatings[0]?.rating || 0,
          editedReviewText: item.review,
          matchedLabourer: foundLabourer,
        },
      });
    },
    [EmployerReviewMade]
  );

  const handleCloseReview = useCallback(() => {
    dispatch({ type: "CLOSE_REVIEW" });
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!state.editingReview) {
      toast.error("No review selected for editing");
      return;
    }

    const updatedReviewData: AddRatingReview = {
      review: state.editedReviewText,
      isAnonymous: state.editingReview.isAnonymous,
      employerRatings: [
        {
          rating: state.editedRating,
          reviewId: state.editingReview.id,
          skillId:
            state.editingReview.employerRatings[0]?.skillId || DEFAULT_SKILL_ID,
        },
      ],
    };

    try {
      await updateReviewMutation.mutateAsync({
        reviewId: state.editingReview.id,
        reviewData: updatedReviewData,
      });
      handleCloseReview();
      refetch();
    } catch (error) {
      console.error("Error in ReviewComponent:", error);
      toast.error(t("Error updating review. Please try again later."));
    }
  }, [
    state.editingReview,
    state.editedReviewText,
    state.editedRating,
    updateReviewMutation,
    handleCloseReview,
    refetch,
    t,
  ]);

  const handleDeleteClick = useCallback((reviewId: number) => {
    dispatch({ type: "SET_DELETE_ITEM", payload: reviewId });
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (state.deleteItem !== null) {
      try {
        await deleteReviewMutation.mutateAsync(state.deleteItem);
        dispatch({ type: "CLOSE_CONFIRMATION" });
        refetch();
      } catch (error) {
        console.error("Error deleting review:", error);
        toast.error(t("Error deleting review. Please try again later."));
        dispatch({ type: "CLOSE_CONFIRMATION" });
      }
    }
  }, [state.deleteItem, deleteReviewMutation, refetch, t]);

  const closeConfirmation = useCallback(() => {
    dispatch({ type: "CLOSE_CONFIRMATION" });
  }, []);

  const memoizedReviewList = useMemo(
    () => (
      <ReviewList
        reviews={EmployerReviewMade?.employerReviews}
        labourers={EmployerReviewMade?.labourers}
        onEdit={handleOpenReview}
        onDelete={handleDeleteClick}
      />
    ),
    [EmployerReviewMade, handleOpenReview, handleDeleteClick]
  );

  const ErrorDisplay = () => (
    <div className="flex flex-col items-center justify-center w-full h-[40vh]">
      <FiAlertCircle className="w-16 h-16 text-red-500 mb-4" />
      <h2 className="text-xl font-semibold mb-2">{t("Error")}</h2>
      <p className="text-red-600">
        {error instanceof Error
          ? error.message
          : t("An unexpected error occurred. Please try again later.")}
      </p>
    </div>
  );

   const LoadingDisplay = () => (
    <div className="flex flex-col items-center justify-center w-full h-[40vh]">
      <RiLoader2Fill className="w-12 h-12 text-[#F0A61F] animate-spin mb-4" />
      <p className="text-lg font-semibold">{t("Loading...")}</p>
    </div>
  );

  if (isLoading) {
    return <LoadingDisplay />;
  }

  if (error) {
    return <ErrorDisplay />;
  }

  return (
    <>
      {EmployerReviewMade?.employerReviews?.length !== 0 ? (
      <div className="pb-[19px] mx-auto flex flex-col mt-[52px]">
        <div className="border-[#D1D0CA] rounded-[11px] min-w-[946px] overflow-x-auto">
          <div className="border p-[15px] flex items-center justify-between border-[#D1D0CA] rounded-t-[11px] pl-[24px]">
            <h5 className="text-[#F0A61F] text-[18px] font-semibold">
              {t("Reviews")}
            </h5>
          </div>
          <div className="[&>*:last-child]:rounded-b-[11px] mb-[190px]">
            {memoizedReviewList}
          </div>
        </div>
      </div>
      ) : (
        <div className="flex items-center justify-center w-full h-[40vh] font-semibold text-lg">
          {t("No reviews Made")}
        </div>
      )}
      <EditReviewModal
        isOpen={state.reviewOpen}
        onClose={handleCloseReview}
        onSubmit={handleSubmit}
        review={state.editingReview}
        editedRating={state.editedRating}
        editedReviewText={state.editedReviewText}
        matchedLabourer={state.matchedLabourer}
        setEditedRating={(rating: any) =>
          dispatch({ type: "SET_EDITED_RATING", payload: rating })
        }
        setEditedReviewText={(text: any) =>
          dispatch({ type: "SET_EDITED_REVIEW_TEXT", payload: text })
        }
        isLoading={updateReviewMutation.isLoading}
      />
      <ConfirmationModal
        isOpen={state.isConfirmationOpen}
        onClose={closeConfirmation}
        onConfirm={handleConfirmDelete}
        isLoading={updateReviewMutation.isLoading}
        itemName={t("review")}
      />
    </>
  );
};

export default ReviewMade;
