import React, { Fragment, useState } from "react";
import PieChartReferral from "../chart/PieChart";
import {
  Aborted,
  Accepted,
  ActionIcon,
  Awarded,
  EditIcon,
  True,
  ViewIcon,
} from "../../assets/images/AllSvg";
import { useTranslation } from "react-i18next";
import { StarIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { useMutation } from "@tanstack/react-query";
import {
  jobAwardAction,
  useJobAwardedList,
  useJobStatisticsData,
} from "../../API/api";
import { toast } from "react-toastify";
import moment from "moment";
import { classNames } from "../../utils/utils";
import Pagination from "../Pagination";
import CircularProgressBar from "../CircularProgressBar";
import { JobAward } from "../../API/types";
import {
  Dialog,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";

enum LabourerJobAwardStatus {
  AWARDED = 1,
  DECLINED = 2,
  ABORTED = 3,
  ACCEPTED = 4,
  COMPLETED = 5,
  CLOSED = 6,
}
const Labourer = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const [laburerActionData, setLaburerActionData] = useState({
    id: 0,
    status: false,
  });
  const [openLabourer, setOpenLabourer] = useState(false);
  const offset = (currentPage - 1) * itemsPerPage;

  const {
    data: jobAwardData,
    isLoading: jobLoading,
    refetch: jobAwardDataRefetch,
  } = useJobAwardedList(offset, itemsPerPage, false);
  const totalRows: number = jobAwardData?.total || 0;
  const startIndex: number = 0;
  const endIndex: number = Math.min(offset + itemsPerPage, totalRows);
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const setRowsPerPage = (rows: number) => {
    setItemsPerPage(rows);
    setCurrentPage(1);
  };

  const resultData: JobAward[] | undefined = jobAwardData?.jobAwards;
  const viewsData: JobAward[] | undefined = resultData?.slice(
    startIndex,
    endIndex
  );
  const { data: jobAward, refetch: jobStstisticData } =
    useJobStatisticsData(true);
  const handleActionJobMutation = useMutation(jobAwardAction, {
    onSuccess: (data) => {
      if (data?.error) {
        toast.error(data?.error?.message);
      } else {
        jobAwardDataRefetch();
        jobStstisticData();
        toast.success(t("Updated the status"));
      }
    },
  });

  const handleAcceptedJobAction = (id: number) => {
    handleActionJobMutation.mutate({
      status: 4,
      jobAwardId: id,
    });
    jobAwardDataRefetch();
    jobStstisticData();
  };

  const handleDeclineJobAction = () => {
    handleActionJobMutation.mutate({
      status: 2,
      jobAwardId: laburerActionData?.id,
    });
    setOpenLabourer(!openLabourer);
    jobAwardDataRefetch();
    jobStstisticData();
  };

  return (
    <>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 sm:grid-cols-1 mt-[52px]">
        <div></div>
        <PieChartReferral jobAward={jobAward} />
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 mb-8 mt-16 gap-[33px]">
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#B6FF59] flex justify-center items-center mx-auto">
              <Awarded />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.awarded}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Awarded")}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#FF4848] flex justify-center items-center mx-auto">
              <Aborted />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.aborted}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Aborted")}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#FFCB45] flex justify-center items-center mx-auto">
              <Accepted />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.accepted}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Accepted")}
              </p>
            </div>
          </div>
        </div>
        <div className="border border-[#D1D0CA] h-[122px] rounded flex justify-center items-center">
          <div>
            <div className="w-[50px] h-[50px] rounded-full bg-[#8348FF] flex justify-center items-center mx-auto">
              <Aborted />
            </div>
            <div className="mt-2">
              <h2 className="text-[#272D29] text-xl font-semibold text-center">
                {jobAward?.declined}
              </h2>
              <p className="text-[#272d29] text-[15px] text-center">
                {t("Jobs Declined")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full overflow-x-scroll">
        <table className="min-w-[968px] w-full divide-y divide-graylighter border border-[#C9C9C9]">
          <thead className="bg-[#EDEDED]">
            <tr>
              <th className=" px-[23px] py-[19px]  text-left text-sm font-semibold text-black w-[190px]">
                {t("Employer")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Skills Required")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Awarded On")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Rate Given")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Status")}
              </th>
              <th className=" px-3 py-[19px]  text-center text-sm font-semibold text-black">
                {t("Action")}
              </th>
            </tr>
          </thead>
          {jobLoading || handleActionJobMutation.isLoading ? (
            <tr>
              <td colSpan={6}>
                <div className="flex items-center justify-center py-8">
                  <CircularProgressBar />
                </div>
              </td>
            </tr>
          ) : (
            <tbody className="Job_Statistic_table">
              {viewsData?.length === 0 ? (
                <tr>
                  <td colSpan={6}>
                    <div className="text-center items-center text-[#F0A61F] text-lg mt-3">
                      {t("No Results Found")}
                    </div>
                  </td>
                </tr>
              ) : (
                viewsData?.map((item: any) => {
                  const matchedLabourer = jobAwardData?.employers?.find(
                    (employer: any) => employer?.id === item?.userId
                  );
                  const matchedSkill = jobAwardData?.skill?.find(
                    (skill: any) =>
                      skill?.id ===
                      item?.job?.jobSkillsRequireds[0]?.skillMasterId
                  );
                  return (
                    <tr className="border-b-2">
                      <td className="py-[26px] px-[23px] w-[190px]">
                        <span className="font-medium  block text-[14px] break-normal leading-[20px] text-secondary">
                          {t(matchedLabourer?.name)}
                        </span>
                        <span className="text-[12px]  break-normal block leading-[16px] text-garydark">
                          {t(item?.address)}
                        </span>
                      </td>
                      <td>
                        <div className="flex gap-[8px] justify-center">
                          <span className="bg-[#F0A61F] text-[13px] leading-[16px] py-[8px] px-[13px] rounded-[24px] text-secondary">
                            {t(matchedSkill?.name as string)}
                          </span>
                        </div>
                      </td>
                      <td>
                        <span className="font-medium  block text-[16px] text-center break-normal leading-[20px] text-black">
                          {moment(item?.awardedOn).format("DD-MM-YYYY hh:mm A")}
                        </span>
                      </td>
                      <td>
                        {item?.avgRating !== null ? (
                          <span className="font-medium flex text-[16px] text-center break-normal leading-[20px] justify-center gap-1">
                            {[0, 1, 2, 3, 4].map((rating) => (
                              <StarIcon
                                key={rating}
                                className={classNames(
                                  item?.avgRating > rating
                                    ? "text-[#F0A61F]"
                                    : "text-[#d3d3d3]",
                                  "h-5 w-5 flex-shrink-0"
                                )}
                                aria-hidden="true"
                              />
                            ))}
                          </span>
                        ) : (
                          <div className="flex gap-[8px] justify-center">
                            <span className="bg-[#F0A61F] text-[13px] leading-[16px] py-[8px] px-[13px] rounded-[24px] text-secondary">
                              {t("NoRating")}
                            </span>
                          </div>
                        )}
                      </td>

                      <td className="text-center">
                        {/* <span
                      className={`${
                        item?.status === 1
                          ? "bg-[#00C44F] "
                          : "bg-[#FF7545] "
                      }rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#272D29]`}
                    >
                      {item.status === 1 ? "Active" : "In Active"}
                    </span> */}
                        {item?.status === LabourerJobAwardStatus.AWARDED ? (
                          <span className="bg-[#00C44F] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#272D29]">
                            {t("Awarded")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status === LabourerJobAwardStatus.DECLINED ? (
                          <span className="bg-[#c23c3c] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Declined")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status === LabourerJobAwardStatus.ABORTED ? (
                          <span className="bg-[#FF7545] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Aborted")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status === LabourerJobAwardStatus.ACCEPTED ? (
                          <span className="bg-[#358856] rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Accepted")}
                          </span>
                        ) : (
                          ""
                        )}
                        {item?.status === LabourerJobAwardStatus.CLOSED ? (
                          <span className="bg-green-600 rounded-full font-medium px-[15px] py-[8px] text-[13px] text-center break-normal leading-[20px] text-[#d7deda]">
                            {t("Closed")}
                          </span>
                        ) : (
                          ""
                        )}
                      </td>
                      <td>
                        <div
                          onClick={() =>
                            setLaburerActionData({
                              id: item.id,
                              status: !laburerActionData?.status,
                            })
                          }
                          className="flex relative justify-center gap-[16px]"
                        >
                          {item?.status === 1 && (
                            <Menu placement="bottom-end">
                              <MenuHandler>
                                <button>
                                  <ActionIcon className="w-[40px] focus:outline-none focus:ring-0 h-[40px] flex items-center cursor-pointer justify-center" />
                                </button>
                              </MenuHandler>
                              <MenuList className="py-1 px-0 min-w-[140px]">
                                <MenuItem
                                  className="rounded-none text-center font-medium hover:bg-[#F0A61F1A] focus:bg-[#F0A61F1A]"
                                  onClick={() =>
                                    handleAcceptedJobAction(item?.id)
                                  }
                                >
                                  {t("Accept")}
                                </MenuItem>
                                <MenuItem
                                  className="rounded-none text-center font-medium hover:bg-[#F0A61F1A] focus:bg-[#F0A61F1A]"
                                  onClick={() => setOpenLabourer(!openLabourer)}
                                >
                                  {t("Decline")}
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          )}
        </table>
      </div>
      {(jobAwardData?.jobAwards.length ?? 0) > 0 && (
        <Pagination
          rowsPerPage={itemsPerPage}
          setRowsPerPage={setRowsPerPage}
          startRow={offset + 1}
          endRow={endIndex}
          totalRows={totalRows}
          handlePageChange={handlePageChange}
          currentPage={currentPage}
          indexOfLastRow={endIndex}
          totalNumber={totalRows ?? 0}
        />
      )}
      <Fragment>
        <Dialog
          open={openLabourer}
          handler={() => setOpenLabourer(true)}
          className=" p-[20px] min-w-[100%] sm:min-w-[70%]  lg:min-w-[996px]   lg:max-h-[996px] mx-auto"
        >
          <>
            <div className="mt-5">
              <h1 className="text-[24px] text-black font-bold text-center mt-4 mb-6">
                {t("ReasonForDeclineThisJob")}
              </h1>
              <div className="flex flex-col mb-10 gap-y-10">
                <div>
                  <h1 className="text-lg font-medium text-black">
                    {t("Write a Reason")}
                  </h1>
                  <textarea className="h-[210px] w-full rounded-xl bg-[#F8F8F8] mt-5" />
                </div>
                <button
                  onClick={handleDeclineJobAction}
                  className="w-full py-2 bg-[#272B2A] text-white font-semibold rounded-lg text-lg"
                >
                  {t("Submit")}
                </button>
              </div>
              <div>
                <button
                  className="absolute right-[20px] text-black top-[20px] "
                  onClick={(e) => {
                    setOpenLabourer(!openLabourer);
                  }}
                >
                  <XMarkIcon className="w-8 h-8 " aria-hidden="true" />
                </button>
              </div>
            </div>
          </>
        </Dialog>
      </Fragment>
    </>
  );
};

export default Labourer;
