import { toast } from "react-toastify";
import Copy from "../utils/clipboard";
import { useTranslation } from "react-i18next";
import { useUserMeQuery, useReferralStatus } from "../API/api";
import {
  Credits,
  MessageIcon,
  People,
  Referrals,
} from "../assets/images/AllSvg";
import { useReferralLink } from "../utils/GeneralUtils";
function MyReferrals() {
  const { data: userData } = useUserMeQuery();
  const { data: referralStatusData } = useReferralStatus();
  const { t } = useTranslation();
    // const referralCode = userData?.user?.referralCode;

  // const queryParams = referralCode
  //   ? `/?referralCode=${referralCode}`
  //   : "";
  // const currentDomain = window.location.origin;
  // const referralLink = `${currentDomain}${queryParams}`;

  const generateReferralLink = useReferralLink();
  const userReferralCode = userData?.user?.referralCode;
  const referralLink = userReferralCode
    ? generateReferralLink(userReferralCode)
    : "";
  const handleCopy = () => {
    Copy(referralLink);
    toast.success("Link Copied!", { autoClose: 1000 });
  };
  const boxContents = [
    {
      number: referralStatusData?.signups || 0,
      title: "Referral SignUps",
      image: <People />,
    },
    {
      number: referralStatusData?.successfulReferrals || 0,
      title: "Successful Referrals",
      image: <Referrals />,
    },
    {
      number: referralStatusData?.creditsEarned || 0,
      title: "Credits Earned",
      image: <Credits />,
    },
  ];
  return (
    <div className=" mt-5 rounded-[8px] overflow-y-auto">
      <div className="w-full mainDiv">
        <div className="w-full subDiv1">
          <div className="div1 pl-3 bg-[#f0a61f] rounded-t-lg flex flex-col gap-2 pt-3 pb-3">
            <h1 className="text-lg font-bold text-white md:text-2xl">
              {t("Refer friends & Earn credits")}
            </h1>
            <p className="text-sm text-gray-800 md:text-base">
              {t("Sharing is Winning. Refer your friends and start earning.")}
            </p>
          </div>
          <div className="bg-white rounded-b-lg div2 ">
            <div className="flex flex-col gap-5 pb-10 pt-7">
              <h1 className="pl-2 text-sm md:text-lg text-blue-gray-700">
                {t("Share the referral")}
              </h1>
              <div className="flex flex-col items-center pl-1 pr-1 md:flex-row md:pl-8 md:pr-8">
                <input
                  value={referralLink}
                  className="w-full px-3 py-2 rounded-full md:w-10/12 md:rounded-r-3xl"
                  type="text"
                  readOnly
                />
                <button
                  onClick={handleCopy}
                  className="bg-[#f0a61f] text-wrap  text-sm md:text-base font-sans py-2 px-4 md:py-3 md:px-6 rounded-full md:rounded-l-3xl mt-2 md:mt-0"
                >
                  {t("Copy")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between w-full mt-10 md:flex-row subDiv1">
          {boxContents?.map((box, index) => (
            <div
              key={index}
              className="flex flex-col items-center justify-center w-full gap-8 p-4 mb-4 bg-white rounded-md shadow-md md:w-1/3 md:mr-2"
            >
              <p className="text-lg font-bold text-gray-800">{box?.number}</p>
              <div className="flex items-center justify-center h-14 w-14">
                {box.image}
              </div>
              <p className="text-base text-gray-900">{t(box.title)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default MyReferrals;
