import React, { useState } from "react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { SearchResult } from "../../API/types";
import { StarIcon } from "@heroicons/react/20/solid";
import { LanguageDto } from "../../API/api";
import { EyeIcon, MessageIcon } from "../../assets/images/AllSvg";
import { FaSpinner } from "react-icons/fa";
import { RemoveFavoriteModal } from "./RemoveFavoriteModal";
import {
  handleFavoriteClick,
  confirmRemoveFavorite,
} from "../../utils/favoritesUtils";
import { useCompleteImageUrl } from "../../utils/GeneralUtils";
import { useTranslation } from "react-i18next";

interface SearchResultItemProps {
  item: SearchResult;
  handleDetails: (userId: number) => void;
  handleShowNumberClick: (id: number) => void;
  handleFavorites: (id: number) => void;
  handleJobAwardClick: (item: SearchResult) => void;
  handleChatClick: (item: SearchResult) => void;
  showPhoneNumber: boolean;
  favouritesData: any[] | undefined;
  languages: LanguageDto[];
  getSkillName: (skillId: number) => string;
  loadingStates: {
    [key: number]: { chat: boolean; award: boolean };
  };
  favoriteLoadingStates: { [key: number]: { add: boolean; remove: boolean } };
  userData: any;
}

const SearchResultItem: React.FC<SearchResultItemProps> = ({
  item,
  handleDetails,
  handleShowNumberClick,
  handleFavorites,
  handleChatClick,
  handleJobAwardClick,
  showPhoneNumber,
  favouritesData,
  languages,
  getSkillName,
  loadingStates,
  favoriteLoadingStates,
  userData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isFavorite = favouritesData?.some((fav) => fav.labourerId === item.id);
  const isLoading =
    favoriteLoadingStates[item.id]?.add ||
    favoriteLoadingStates[item.id]?.remove;

  const starRatingValues = [0, 1, 2, 3, 4];
  const loadingState = loadingStates[item.userId] || {
    chat: false,
    award: false,
  };
  const renderLanguages = () =>
    item?.languages?.slice(0, 3).map((lang) => {
      const matchingLanguage = languages?.find(
        (l) => l?.id === lang?.languageId
      );
      return (
        <button className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]">
          {matchingLanguage?.name}
        </button>
      );
    });

  const renderSkills = () =>
    item.skills.map((skill) => (
      <span className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]">
        {getSkillName(skill.skillId ?? 0)}
      </span>
    ));

  //IMAGE UTILS FUNCTIONS
  const getCompleteImageUrl = useCompleteImageUrl();

  //FAVOURITE UTILS FUNCTIONS
  const onFavoriteClick = () =>
    handleFavoriteClick(isFavorite, handleFavorites, item.id, setIsModalOpen);
  const onConfirmRemoveFavorite = () =>
    confirmRemoveFavorite(handleFavorites, item.id, setIsModalOpen);
  const { t } = useTranslation();

  const isHybridOrEmployer =
    userData?.user?.roles.includes("employer") ||
    (userData?.user?.roles.includes("employer") &&
      userData?.user?.roles.includes("labourer"));

  return (
    <div className="relative max-w-md mx-auto mb-8 overflow-hidden bg-white shadow-md rounded-xl md:max-w-full">
      <div className="md:flex">
        <div className="md:shrink-0 lg:mr-3">
          <img
            src={
              item?.profileUrl
                ? getCompleteImageUrl(item.profileUrl)
                : require(`../../assets/images/profile.png`)
            }
            onClick={() => handleDetails(item.userId)}
            alt=""
            className="object-cover w-full h-48 cursor-pointer md:h-full md:w-48"
          />
        </div>
        <div className="w-full p-4 md:p-2">
          <div className="flex flex-col items-center gap-3 md:justify-between md:flex-row md:items-center">
            <div className="flex flex-col w-full gap-10 md:flex-row">
              <div className="flex items-center gap-3">
                <div className="text-lg font-semibold tracking-wide text-indigo-900 uppercase">
                  {t(item.name as string)}
                </div>
                <div
                  className={`bg-[${
                    isFavorite ? "#00C54F" : "#eb300a"
                  }] w-[17px] h-[17px] rounded-full`}
                ></div>
              </div>
              <div className="flex gap-2">
                <div className="flex">
                  {starRatingValues.map((rating) => (
                    <StarIcon
                      key={rating}
                      className={`${
                        item.averageStarRating > rating
                          ? "text-[#F0A61F]"
                          : "text-[#d3d3d3]"
                      } h-5 w-5 flex-shrink-0`}
                      aria-hidden="true"
                    />
                  ))}
                </div>
                <p className="text-[#7C786D] text-sm font-roboto">
                  {/* {rating?.} */}0 {t("reviews")}
                </p>
              </div>
            </div>
            <div className="flex items-center w-full gap-5 mt-2 mb-2 md:gap-3 md:justify-end">
              <button
                onClick={() => handleShowNumberClick(item.id)}
                className="bg-white border border-[#999485] text-[#999485] rounded-[34px] md:h-[39px] h-[34px] w-[130px] md:w-[125px] flex justify-center items-center text-[8px] md:text-[11px] font-semibold"
              >
                {showPhoneNumber ? (
                  <p className="text-sm">{item.phoneNumber}</p>
                ) : (
                  <div className="flex justify-center items-center gap-1 text-[#999485] ">
                    <EyeIcon color="#999485" />
                    {t("Show Phone")}
                  </div>
                )}
              </button>
              <button
                onClick={() => handleChatClick(item)}
                className="border-[#999485] border-[1px] rounded-[34px] w-[34px] h-[34px] md:w-[44px] md:h-[44px] flex justify-center items-center"
                disabled={loadingState.chat}
              >
                {loadingState.chat ? (
                  <FaSpinner className="inline-block  animate-spin text-[#F0A61F] w-5 h-5" />
                ) : (
                  <MessageIcon />
                )}
              </button>
              {isHybridOrEmployer && (
                <button
                  onClick={onFavoriteClick}
                  className="border-[#999485] border rounded-full w-[34px] h-[34px] md:w-[44px] md:h-[44px] flex justify-center items-center hover:bg-[#FBE9C7] transition-colors duration-300"
                >
                  {isLoading ? (
                    <div className="w-5 h-5 border-2 border-t-[#F0A61F] border-r-[#F0A61F] border-b-[#F0A61F] border-l-transparent rounded-full animate-spin" />
                  ) : isFavorite ? (
                    <AiFillHeart className="w-6 h-6 text-red-400 transition-colors duration-300" />
                  ) : (
                    <AiOutlineHeart className="w-6 h-6 text-[#999485] transition-colors duration-300" />
                  )}
                </button>
              )}
            </div>
          </div>
          <div
            onClick={() => handleDetails(item.userId)}
            className="border-b-[#E9E8E3] border-b-[1px] pb-[8px] cursor-pointer"
          >
            <h2 className="text-[#272D29] md:text-[15px] text-sm font-semibold mt-2 md:mt-0">
              {t("Description")}
            </h2>
            <p className="text-[#272D29] font-roboto text-[16px] tracking-[0.16px]">
              {item.description}{" "}
              <span className="text-[#F0A61F] cursor-pointer">{t("more")}</span>
            </p>
          </div>
          <div className="border-b-[#E9E8E3] border-b-[1px]">
            <h2 className="md:mt-1 md:mb-1 mt-2 text-[#272D29] md:text-[15px] text-sm font-semibold">
              {t("Location")}
            </h2>
            <div className="flex gap-2 mt-1 md:mt-1 md:mb-1">
              <p className="text-[#272D29] font-roboto text-[16px] tracking-[0.16px]">
                {t(item.location)}
                <span className="text-[#7C786D] text-[13px] ml-[10px]">
                  {item.distance.toFixed(2)} {t("Km from you")}
                </span>
              </p>
            </div>
          </div>
          <div className="items-center mt-4 md:flex md:justify-between md:mt-0">
            <div className="flex flex-col md:gap-6 md:flex-row">
              <div className="flex flex-col md:flex-row md:gap-8 md:items-center">
                {item.languages?.length && item.languages.length > 0 && (
                  <div className="border-b-[#E9E8E3] border-b-[1px] pb-[8px]">
                    <h2 className="mt-2 text-[#272D29] md:text-[15px] text-sm font-semibold mb-1">
                      {t("Languages")}
                    </h2>
                    <div className="flex gap-1 md:gap-2">
                      {renderLanguages()}
                      {item.languages.length > 3 && (
                        <button
                          type="button"
                          className="bg-[#E9E8E3] rounded-[24px] text-[#272D29] font-roboto text-[14px] p-[4px_13px]"
                          onClick={() => handleDetails(item.userId)}
                        >
                          +{item.languages.length - 3}
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="border-b-[#E9E8E3] border-b-[1px] pb-[8px]">
                <h2 className="mt-2 text-[#272D29] md:text-[15px] text-sm font-semibold mb-1">
                  {t("SkillsWithExperience")}
                </h2>
                <div className="flex gap-2">{renderSkills()}</div>
              </div>
            </div>
            <div className="mt-5">
              <button
                onClick={() => handleJobAwardClick(item)}
                className="bg-[#FBE9C7] border border-[#999485] rounded-lg h-[39px] px-4 items-center text-[14px] font-medium text-[#272D29]"
                disabled={loadingState.award}
              >
                {loadingState.award ? (
                  <>
                    <span>awarding..</span>
                    <FaSpinner className="inline-block text-[#F0A61F] animate-spin ml-2 w-5 h-5" />
                  </>
                ) : (
                  t("Award This Job")
                )}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <RemoveFavoriteModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={onConfirmRemoveFavorite}
        itemName={item?.name}
      />
    </div>
  );
};

export default SearchResultItem;
