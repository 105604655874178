import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import "chartjs-plugin-datalabels";
import { useTranslation } from "react-i18next";
import { PieChartIcon } from "../../assets/images/AllSvg";
import { EmptyData } from "./EmptyData";
export default function IndexPage({ jobAward }: any) {
  const canvas = useRef<HTMLCanvasElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!jobAward) return;
    const ctx = canvas.current?.getContext("2d");
    if (!ctx) return;
    let chartStatus = Chart.getChart(ctx.canvas.id);
    if (chartStatus) {
      chartStatus.destroy();
    }

    if (isDataEmpty(jobAward)) return;

    new Chart(ctx, {
      type: "pie",
      data: {
        labels: [
          t("Jobs Awarded"),
          t("Job Accepted"),
          t("Job Declined"),
          t("Jobs Aborted"),
        ],
        datasets: [
          {
            data: [
              jobAward.awarded,
              jobAward.accepted,
              jobAward.declined,
              jobAward.aborted,
            ],
            backgroundColor: ["#B6FF59", "#FFCB45", "#36A2EB", "#FF6384"],
            borderColor: ["#B6FF59", "#FFCB45", "#36A2EB", "#FF6384"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
            display: false,
          },
          datalabels: {
            color: "#fff",
            font: {
              weight: "bold",
              size: 14,
            },
            // padding: 4,
            formatter: function (value, context) {
              return value !== 0 ? value.toString() : "";

              // return "";
            },
          },
        },
      },
    });
  }, [jobAward, t]);

  const isDataEmpty = (data: any) => {
    return (
      data?.awarded === 0 &&
      data?.accepted === 0 &&
      data?.declined === 0 &&
      data?.aborted === 0
    );
  };

  return (
    <div className="container w-full h-[200px] flex items-center justify-center  r">
      {!jobAward || isDataEmpty(jobAward) ? (
        <EmptyData Icon={PieChartIcon} />
      ) : (
        <canvas
          id="myChart"
          ref={canvas}
          className="chart-canvas"
          width={300}
          height={300}
        ></canvas>
      )}
    </div>
  );
}
