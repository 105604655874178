import React, { useState } from "react";
import { addSkillAttributesValue } from "../../API/api";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import CircularProgressBar from "../CircularProgressBar";

interface Attribute {
  id: number;
  attributeLabel: string;
  skillId: number;
}

interface SkillAttribute {
  id: number;
  skillMasterAttributeId: number;
  skillId: number;
  enabled: boolean;
  isVisible: boolean;
}

interface MasterAttribute {
  id: number;
  attributeLabel: string;
}

interface Props {
  skillAttributes: SkillAttribute[];
  masterAttributes: MasterAttribute[];
  skillIsLoading: boolean;
  nextStep: () => void;
}

const AttributeDisplay: React.FC<{
  attributes: Attribute[][];
  nextStep: () => void;
  skillIsLoading: boolean;
}> = ({ attributes, nextStep, skillIsLoading }) => {
  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const { mutate, isLoading } = useMutation(addSkillAttributesValue, {
    onSuccess: () => {
      // toast.success("success");
      nextStep();
    },
    onError: () => {
      toast.error("Form submission error");
    }
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const hasErrors = Object.keys(formData).some(
        (key) => !formData[key].trim()
      );
      if (hasErrors) {
        return;
      }
      const skillAttributeValue = Object.keys(formData).map((key) => ({
        skillAttributeId: parseInt(key),
        skillAttributeValue: formData[key].trim()
      }));
      mutate(skillAttributeValue);
    } catch (error) {
      toast.error("Form submission error");
    }
  };
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    attribute: Attribute
  ) => {
    setFormData({
      ...formData,
      [`${attribute?.id}`]: e.target.value
    });
  };
  const handleSpaceValidation = (
    e: React.FocusEvent<HTMLInputElement>,
    attribute: Attribute
  ) => {
    const inputValue = e.target.value;
    const hasError = inputValue.trim() === "";
    setErrors({
      ...errors,
      [`${attribute?.id}`]: hasError
        ? `${attribute?.attributeLabel} is required`
        : ""
    });
  };

  return (
    <div className=" border border-gray-300  shadow-lg rounded-md mt-[45px] ">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="mt-2 md:mt-4 md:ml-6 ml-3 ">
          <span className="text-xl text-blue-gray-400 ">Skill Attributes</span>
        </div>
        {skillIsLoading ? (
          <CircularProgressBar />
        ) : (
          attributes?.map((group, index) => (
            <div
              key={index}
              className="max-w-[857px] w-full md:mt-[10px] mt-2  sm:px-5 px-3  mx-auto md:pl-7 md:pr-7 pr:5 pl:5 "
            >
              <div className="flex flex-col gap-[23px] overflow-y-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5 bg-gray-300 md:p-10 p-5 rounded-md">
                  {group.map((attribute, index) => (
                    <div key={index}>
                      <input
                        type="text"
                        required
                        placeholder={attribute?.attributeLabel}
                        className="shadow-md bg-white rounded-lg px-4 py-2 w-full"
                        onChange={(e) => handleChange(e, attribute)}
                        onBlur={(e) => handleSpaceValidation(e, attribute)}
                      />
                      {errors[`${attribute?.id}`] && (
                        <span className="text-red-500">
                          {errors[`${attribute?.id}`]}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))
        )}
        <div className="flex justify-end mt-5 pb-5 pr-6">
          <button
            type="submit"
            className="text-white bg-[#272D29] hover:bg-[#585d5a] py-3 px-6 rounded-lg font-roboto font-medium text-lg leading-6"
          >
            {isLoading ? "Loading..." : "Next"}
          </button>
        </div>
      </form>
    </div>
  );
};

// Group SkillAttributes functions
const SkillAttributesDisplay: React.FC<Props> = ({
  skillAttributes,
  masterAttributes,
  skillIsLoading,
  nextStep
}) => {
  const groupedAttributes: Record<number, SkillAttribute[]> = {};
  skillAttributes?.forEach((attributes) => {
    if (!groupedAttributes[attributes?.skillId]) {
      groupedAttributes[attributes?.skillId] = [];
    }
    groupedAttributes[attributes?.skillId]?.push(attributes);
  });

  const attributeGroups: Attribute[][] = Object.values(groupedAttributes).map(
    (group) =>
      group.map((attributes) => {
        const masterAttribute = masterAttributes?.find(
          (masterAttribute) =>
            masterAttribute?.id === attributes?.skillMasterAttributeId
        );
        return {
          id: attributes.id,
          attributeLabel: masterAttribute
            ? masterAttribute?.attributeLabel
            : "",
          skillId: attributes?.skillId
        };
      })
  );
  return (
    <AttributeDisplay
      attributes={attributeGroups}
      nextStep={nextStep}
      skillIsLoading={skillIsLoading}
    />
  );
};

export default SkillAttributesDisplay;
