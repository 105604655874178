import React from "react";
import {
  UserSubscriptionDto,
  useSubscriptionDetails,
  useUserMeQuery,
} from "../../API/api";
import moment from "moment";
import CircularProgressBar from "../CircularProgressBar";

const SubscriptionDetails: React.FC = () => {
  const { data: userData } = useUserMeQuery();
  const labourerId = userData?.labourerDetails?.userId;
  const {
    data: subscriptionData,
    isLoading,
    isError,
  } = useSubscriptionDetails(labourerId);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <CircularProgressBar />
        </div>
      ) : isError ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            Error loading data
          </div>
        </div>
      ) : subscriptionData?.length === 0 ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            No subscription data.
          </div>
        </div>
      ) : (
        <>
          {subscriptionData?.map((subscription: UserSubscriptionDto) => (
            <div className="pt-6 ">
              <div className="  md:gap-10 flex flex-col md:flex-row   ">
                <div className="mb-6 flex-col   ">
                  <p className="text-[#F0A61F] font-semibold text-lg">
                    Current Plan:
                  </p>
                  <p className="text-base font-semibold text-gray-800 mt-2">
                    {subscription?.currentPlan}
                  </p>
                </div>
                <div className="mb-6 flex-col">
                  <p className="text-[#F0A61F] font-semibold text-lg">
                    Last Renewed On:
                  </p>
                  <p className="text-base font-semibold text-gray-800 mt-2">
                    {moment(subscription?.lastRenewedOn).format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="mb-6 flex-col">
                  <p className="text-[#F0A61F] font-semibold text-lg">
                    End Date:
                  </p>
                  <p className="text-base font-semibold text-gray-800 mt-2">
                    {moment(subscription?.subscriptionEndsOn).format(
                      "DD-MM-YYYY"
                    )}
                  </p>
                </div>
              </div>
              <div className="flex  flex-col gap-6 mt-3 lg:mt-10 md:flex-row lg:flex-row  xl:flex-row 2xl:flex-row ">
                <div className="mb-6 flex-col ">
                  <p className="text-[#F0A61F] font-semibold text-lg">
                    Days to Expiry:
                  </p>
                  <p className="text-base font-semibold text-gray-800 mt-2">
                    {subscription?.daysToExpire}
                  </p>
                </div>
                <div className="mb-6 flex-col">
                  <p className="text-[rgb(240,166,31)] font-semibold text-lg">
                    Upgrade to:
                  </p>
                  <div className="flex gap-2 mt-2">
                    {["Silver", "Gold", "Platinum"].map((plan) => (
                      <button
                        key={plan}
                        className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-2 px-4 text-[12px] rounded-lg w-full sm:w-auto"
                      >
                        {plan}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default SubscriptionDetails;
