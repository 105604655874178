import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  usePreviousSearchDetails,
  useUserMeQuery,
  useServiceCategoryQuery,
} from "../../API/api";
import moment from "moment";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import CircularProgressBar from "../CircularProgressBar";
interface SearchData {
  location: string;
  createdAt: string;
  resultCount: number;
  skills: string;
}
interface SkillItems {
  id: number;
  name: string;
}
const SearchHistory = () => {
  const { t } = useTranslation();
  const { data: userData } = useUserMeQuery();
  const userId = userData?.user.id;
  const { data: skillData, isLoading: isSkillLoading } =
    useServiceCategoryQuery();
  const {
    data: searchHistoryData,
    isLoading,
    isError,
  } = usePreviousSearchDetails(userId);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = searchHistoryData?.slice(indexOfFirstRow, indexOfLastRow);
  const totalRows = searchHistoryData?.length;
  const startRow = (currentPage - 1) * rowsPerPage + 1;
  const endRow = Math.min(currentPage * rowsPerPage, totalRows);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className="bg-white jobsMaindiv flex flex-col items-center  pl-4 rounded-md pb-2 shadow-md">
        <div className="mb-4 flex justify-between p-2 items-center w-full">
          <div>
            <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto">
              Search History
            </span>
          </div>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              <CircularProgressBar />
            </div>
          </div>
        ) : isError ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              Error loading data
            </div>
          </div>
        ) : currentRows.length === 0 ? (
          <div className="flex items-center justify-center h-full md:pb-20  pb-10">
            <div className="text-center text-[#F0A61F] text-lg font-semibold">
              Currently, there are no search history
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center w-full p-1 ">
            <table className="min-w-full  ">
              <thead>
                <tr className="w-full">
                  <th className="px-4 py-2 text-left text-gray-700">
                    {t("Service")}
                  </th>
                  <th className="px-4 py-2 text-left text-gray-700">
                    {t("Location")}
                  </th>
                  <th className="px-4 py-2  text-left text-gray-700">
                    {t("Searched on")}
                  </th>
                  <th className="px-4 py-2 text-left text-gray-700">
                    {t("Result")}
                  </th>
                </tr>
              </thead>
              {currentRows?.map((data: SearchData, index: number) => {
                const skillIds = JSON.parse(data?.skills)[0];
                return (
                  <tbody>
                    <tr>
                      <td className="px-4 py-2">
                        {skillData?.map((skill: SkillItems) => {
                          if (skill?.id === skillIds) {
                            return (
                              <div
                                key={skill?.id}
                                className="bg-orange-200 text-[13px] leading-[16px] py-[8px] px-[13px] rounded-[24px] text-secondary block w-fit"
                              >
                                {skill?.name}
                              </div>
                            );
                          }
                          return null;
                        })}
                      </td>
                      <td className="px-4 py-3 text-gray-800">
                        {" "}
                        {data?.location || "Kochi"}
                      </td>
                      <td className="px-4 py-3 text-gray-800">
                        {" "}
                        {moment(data?.createdAt).format("DD/MM/YYYY")}
                      </td>
                      <td className="px-4 py-3 text-gray-800">
                        {" "}
                        <span>{data?.resultCount}</span>
                      </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
            <div className="flex gap-[14px] items-center justify-end  flex-wrap ">
              <p className="text-[#272D29] text-[17px]">
                {startRow}-{endRow} of {totalRows}
              </p>
              <div className="flex gap-4 p-5">
                <button
                  className="bg-[#FBE9C7] w-[35px] h-[35px] rounded-full flex justify-center items-center text-[24px]"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                >
                  <BiChevronLeft />
                </button>
                <button
                  className="bg-[#FBE9C7] w-[35px] h-[35px] rounded-full flex justify-center items-center text-[24px]"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={indexOfLastRow >= searchHistoryData?.length}
                >
                  <BiChevronRight />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchHistory;
