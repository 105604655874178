import React, { useState, ChangeEvent } from "react";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js/auto";
import { useJobStatisticsData } from "../../API/api";
import CircularProgressBar from "../CircularProgressBar";
import { EmptyData } from "../chart/EmptyData";
import { PieChartIcon } from "../../assets/images/AllSvg";
import { t } from "i18next";

Chart.register(ChartDataLabels);

const JobPieChartEmployer = () => {
  const {
    data: jobStatistics,
    isError,
    isLoading,
  } = useJobStatisticsData(false);
  const [selectedMonth, setSelectedMonth] = useState<string>("January");
  const chartData: any = {
    January: jobStatistics
      ? [
          jobStatistics.awarded,
          jobStatistics.accepted,
          jobStatistics.declined,
          jobStatistics.aborted,
        ]
      : [0, 0, 0, 0],
    February: jobStatistics,
    March: jobStatistics,
  };
  const jobStats = jobStatistics
    ? [
        jobStatistics.awarded,
        jobStatistics.accepted,
        jobStatistics.declined,
        jobStatistics.aborted,
      ]
    : [0, 0, 0, 0];

  const allValuesZero = jobStats.every((value) => value === 0);

  const statusColors: { [key: string]: string } = {
    Awarded: "#B6FF59",
    Accepted: "#FFCB45",
    Declined: "#36A2EB",
    Aborted: "#FF6384",
  };
  const handleMonthChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedMonth(event.target.value);
  };

  const data = {
    labels: Object.keys(statusColors),
    datasets: [
      {
        label: "Job Status",
        data: jobStats,

        backgroundColor: Object.values(statusColors),
        borderColor: ["#B6FF59", "#FFCB45", "#36A2EB", "#FF6384"],
        borderWidth: 1,

        datalabels: {
          color: "white",
          font: {
            size: 14,
          },
          formatter: (value: number) => {
            // If the value is 0, don't display the label
            return value !== 0 ? value.toString() : "";
          },
        },
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: true,
      },
    },
  };

  return (
    <>
      <div className="mb-4 flex justify-between p-2 items-center w-full ">
        <div className="">
          <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto">
          {t("Job Statistics")}
          </span>
        </div>
        {/* <div>
          <select
            id="month"
            className="border border-[#BDBDBD] p-2 rounded-md"
            onChange={handleMonthChange}
            value={selectedMonth}
          >
            <option value="January">January</option>
            <option value="February">February</option>
            <option value="March">March</option>
          </select>
        </div> */}
      </div>
      {isLoading ? (
        <div className="flex justify-center items-center h-full">
          <CircularProgressBar />
        </div>
      ) : isError ? (
        <div className="text-center text-[#F0A61F] text-lg mt-3 h-full">
          {t("Error loading data")}
        </div>
      ) : allValuesZero ? (
        <EmptyData Icon={PieChartIcon} />
      ) : (
        <div className="flex flex-col items-center w-full">
          <div className="mb-4 w-[200px] h-[200px] 2xl:w-[250px] 2xl:h-[250px] ">
            <Pie data={data} options={options} />
          </div>
          <div className="flex  items-center ">
            {data.labels.map((label, index) => (
              <div key={index}>
                <div key={index} className="flex gap-1  items-center ">
                  <div
                    className="inline-block w-3 h-3 rounded-full "
                    style={{
                      backgroundColor: data.datasets[0].backgroundColor[index],
                    }}
                  ></div>
                  <div className="lg:mr-5 2xl:mr-5 xl:mr-5 mr-2 text-[8px] font-semibold  md:text-xs lg:text-xs xl:text-xs 2xl:text-xs text-[#F0A61F]">{`${label}`}</div>
                </div>
                <div className="text-[#2B3674] flex ml-7">
                  <span className="font-roboto font-bold 2xl:text-xl text-md leading-[30px] tracking-[2%]">
                    {`${chartData[selectedMonth][index]}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default JobPieChartEmployer;
