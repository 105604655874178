import { Dialog } from "@material-tailwind/react";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useUserMeQuery } from "../../API/api";

const AwardThisJob = ({
  openAwardMenu,
  setOpenAwardMenu,
}: {
  setOpenAwardMenu: React.Dispatch<React.SetStateAction<boolean>>;
  openAwardMenu: boolean;
}) => {
  const navigate = useNavigate();
  const { data: userData, refetch } = useUserMeQuery();

  const handleConfirmClick = async () => {
    setOpenAwardMenu(false);

    const userRoles = userData?.user?.roles || [];
    if (userRoles.length === 1 && userRoles.includes("labourer")) {
      await refetch();
    }

    navigate("/jobstatistics?tab=employer", { replace: true });
  };

  return (
    <Fragment>
      <Dialog
        open={openAwardMenu}
        handler={() => setOpenAwardMenu(!openAwardMenu)}
        className="p-[20px] min-w-[100%] sm:min-w-[545px] lg:min-w-[750px] lg:max-h-[750px] w-full"
      >
        <>
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3 className="text-xl font-semibold leading-6 text-gray-900">
                Award Job
              </h3>
              <div className="mt-2">
                <p className="text-[16px] font-semibold text-gray-700">
                  You have successfully awarded this job. Please wait for the
                  confirmation from the labourer.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-gray-600 px-7 py-[6px] text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto"
              onClick={handleConfirmClick}
            >
              Okay
            </button>
          </div>
        </>
      </Dialog>
    </Fragment>
  );
};

export default AwardThisJob;
