import { CHAT_COUNT } from "./../constants/QueryKeys";
import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import {
  ApiError,
  ErrorResponse,
  FavouritesDto,
  FcmTokenPayload,
  JobResponse,
  SearchResultResponse,
  Service,
  SignupResponse,
  UserAvailability,
  UserDetailResponse,
  VerifyMobileResponse,
  VerifyOtpResponse,
} from "./types";
import jwt_decode from "jwt-decode";
import {
  _token,
  isAuthenticated,
  setToken,
  verifiedToken,
} from "../utils/jwtUtils";
import { smartStorage } from "../utils/SmartStorage";
import * as QueryKey from "../constants/QueryKeys";
import { JobAwardStatus } from "../component/JobStatistics/Employer";

export enum MessageType {
  TEXT = "TEXT",
  MEDIA = "MEDIA",
  LOCATION = "LOCATION",
}

export interface LabourerSkillAttributeDto {
  skillAttributeId: number;
  skillAttributeValue: string;
}

export interface LabourerSkillAttributeDto {
  skillAttributeId: number;
  skillAttributeValue: string;
}

export interface GetPaymentCardDto {
  id: number;
  name: string;
  description: string;
  price: number;
  validityDays: number;
  status: number;
  createdAt: string;
  updatedAt: string;
}

export class NoContentResponse {}

export interface IMessage {
  id: number;
  roomId: number;
  userId: number;
  message: string;
  messageTimestamp: string;
  type: MessageType;
  mediaUrl: string | null;
  locationCoordinate: string | null;
}

export interface ChatRoomDto {
  chatRoomId: number;
  jobId: number;
  laborId: number;
  userId: number;
  userName: string;
  timeStamp: string;
  lastMessage: string;
  skillMasterIds: any;
}

export interface LanguageDto {
  id: number;
  name: string;
  code: string;
}

export interface TotalUnreadCountDto {
  count: number;
}

export interface CreateJobRequest {
  location: string;
  latitude: number;
  longitude: number;
  skillIds: number[];
  labourerId: number;
}

interface ReferralStatusDto {
  creditsEarned: number;
  signups: number;
  successfulReferrals: number;
}
interface UnreadCountListDto {
  roomId: number;
  unreadCount: number;
}
export interface FaqsDto {
  question: string;
  answer: string;
  status: boolean;
  updatedDateTime: string;
}

export interface JobDto {
  id: number;
}

export interface BankDetailsDto {
  id: number;
  bankName: string;
  accountNumber: string;
  ifsc: string;
  accountHolderName: string;
  createdAt: Date;
  isDefault: boolean;
  isDeleted: boolean;
  userId?: number | undefined;
}

export interface PayInTransactionsHistory {
  subscriptionId: number;
  subscriptionName: string;
  walletAmount: number;
  paymentGatewayAmount: number;
  walletTransactionDate: Date;
  paymentGatewayDate: Date;
  paymentTxnId: number;
  paymentMethod: string;
}

export interface PayOutTransactionsHistory {
  paymentid: number;
  transactionDate: Date;
  referenceId: string;
  amount: number;
  accountNumber: string;
  bankName: string;
  ifsc: string;
}

interface JobStatusUpdateDto {
  status: JobAwardStatus;
  jobAwardId: number;
}
export interface OverallRatingDto {
  overallRating: number;
}
export interface SkillRateDto {
  avgRating: number;
  skillName: string;
}
export interface ProfileViewMonthDto {
  month: string;
  monthIndex: number;
  profileViews: number;
}

export interface TotalProfileViewDto {
  profileViews: number;
}

export interface ReferralIncentiveLegendsDto {
  referralRegistrations: number;
  successCount: number;
  totalEarnings: number;
  totalPayouts: number;
}
export interface IncentiveDataDto {
  txnMonth: number;
  monthName: string;
  earnings: number;
  payouts: number;
}
export interface PaymentTransactionDto {
  payInCount: number;
  payOutCount: number;
  totalCount: number;
  txnStatus: string;
}
export interface UserSubscriptionDto {
  currentPlan: string;
  daysToExpire: string;
  lastRenewedOn: string;
  membershipPlanId: number;
  registeredOn: string;
  subIndex: number;
  subscriptionEndsOn: string;
  uID: number;
}
export interface NotificationCountDto {
  chatNotifCount: number;
  expiryNotifCount: number;
  paymentNotifCount: number;
  total: number;
}
export interface UnreadNotificationDto {
  createdAt: string;
  id: number;
  isDeleted: number;
  payload: string;
  read: number;
  type: number;
  userId: number;
}
export interface FeedbackDetailsDto {
  name: string;
  message: string;
  emailId: string;
  status: string;
  phoneNumber: string;
}
interface GeneralDataDto {
  configurations: {
    REFERRAL_INVITATION_LINK: string;
    RESOURCES_BASE_URL: string;
    SAS_TOKEN_READONLY: string;
    GOOGLE_MAPS_API_KEY: string;
  };
  updateStatus: number;
}
type HttpMethod = "GET" | "HEAD" | "POST" | "PATCH" | "PUT" | "DELETE";

const STALE_TIME = 60000 * 5; // 5 minutes

export const { REACT_APP_API_URL } = process.env;

export const queryClient = new QueryClient();

const isAccessTokenExpired = (accessToken: string | null) => {
  if (!accessToken) {
    return true;
  }
  let decodedToken = jwt_decode(accessToken);
  let currentDate = new Date();

  return (decodedToken as any).exp * 1000 < currentDate.getTime();
};

const refreshAccessToken = async (refreshToken: string | null) => {
  if (!refreshToken) {
    throw new Error("No refresh token available");
  }
  try {
    const response = await fetch(`${REACT_APP_API_URL}/auth/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (response.ok) {
      const data = await response.json();
      const { accessToken } = data;
      setToken(accessToken);
      return accessToken;
    } else {
      throw new Error("Failed to refresh token");
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw new Error("Failed to refresh token");
  }
};

const logout = () => {
  const cookie = {
    name: "jwt",
    expires: new Date(Date.now() - 1000),
    httpOnly: true,
  };
  const cookieString = JSON.stringify(cookie, (key, value) => {
    if (key !== "name" && key !== "httpOnly" && key !== "expires") {
      return "";
    }
  });
  document.cookie = cookieString;
  queryClient.invalidateQueries();
  return document.cookie;
};

export const apiEndpoint = async <T>(
  url: string,
  requestData: Record<string, any>,
  method: HttpMethod,
  useVerifiedToken: boolean = false
): Promise<T> => {
  let token = useVerifiedToken ? verifiedToken() : _token();
  if (!useVerifiedToken) {
    const isTokenExpired = isAccessTokenExpired(token);
    if (isTokenExpired) {
      const cookie = document?.cookie !== "" ? document?.cookie : '""';
      const refreshTokenStorage = JSON.parse(cookie)?.value;
      const refreshTokenExists = refreshTokenStorage;
      if (refreshTokenExists !== undefined) {
        try {
          const refreshedAccessToken = await refreshAccessToken(
            refreshTokenStorage
          );
          token = refreshedAccessToken;
        } catch (error) {
          logout();
          throw new Error("Token refresh failed");
        }
      }
    }
  }
  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  if (method !== "GET" && method !== "HEAD") {
    options.body = JSON.stringify(requestData);
  }
  const response = await fetch(url, options);
  if (response.status === 204) {
    return new NoContentResponse() as T;
  }
  if (response.ok) {
    const data = await response.json();
    return data as T;
  } else {
    const errorResponse = (await response.json()) as ErrorResponse;
    throw ApiError.copy(errorResponse.error);
  }
};

export const apiEndpointFile = async <T>(
  url: string,
  requestData: FormData,
  method: string
) => {
  let token = _token();
  const isTokenExpired = isAccessTokenExpired(token);
  if (isTokenExpired) {
    const cookie = document?.cookie !== "" ? document?.cookie : '""';
    const refreshTokenStorage = JSON.parse(cookie)?.value;
    const refreshTokenExists = refreshTokenStorage;
    if (refreshTokenExists !== undefined) {
      try {
        const refreshedAccessToken = await refreshAccessToken(
          refreshTokenStorage
        );
        token = refreshedAccessToken;
      } catch (error) {
        logout();
        throw new Error("Token refresh failed");
      }
    }
  }
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const options: RequestInit = {
    method,
    headers: headers,
    body: requestData,
  };
  const response = await fetch(url, options);
  if (response.ok) {
    const data = await response.json();
    return data as T;
  } else {
    const errorResponse: ErrorResponse = await response.json();
    throw errorResponse.error;
  }
};

export const signupUser = async (signData: Record<string, any>) => {
  return apiEndpoint<SignupResponse>(
    `${REACT_APP_API_URL}/users/signup`,
    signData,
    "POST",
    true
  );
};

export const verifyMobile = async (loginData: Record<string, any>) => {
  return apiEndpoint<VerifyMobileResponse>(
    `${REACT_APP_API_URL}/auth/verify-mobile`,
    loginData,
    "POST"
  );
};

export const verifyOtpMutate = async (otp: Record<string, any>) => {
  return apiEndpoint<VerifyOtpResponse>(
    `${REACT_APP_API_URL}/auth/verify-otp`,
    otp,
    "POST"
  );
};

export const ResendOtpMutate = async (otp: Record<string, any>) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/auth/resend-otp`,
    otp,
    "POST"
  );
};

export const useGetFavourites = () => {
  return useQuery({
    queryKey: [QueryKey.GET_FAVOURITES],
    enabled: isAuthenticated(),
    queryFn: () =>
      apiEndpoint<Array<FavouritesDto>>(
        `${REACT_APP_API_URL}/favourites`,
        {},
        "GET"
      ),
  });
};

export const useFavouritesBy = (hasLabourerRole: boolean) => {
  return useQuery({
    queryKey: [QueryKey.GET_FAVOURITES_BY],
    enabled: hasLabourerRole,
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/favourites-by`,
        {},
        "GET"
      ),
  });
};

export const useFeedbackMaster = () => {
  return useQuery({
    queryKey: [QueryKey.GET_FEEDBACKS],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/feedback-master`,
        {},
        "GET"
      ),
  });
};

export const sendFeedbackMutation = async (
  feedbackData: Record<string, any>
) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/feedbacks`,
    feedbackData,
    "POST"
  );
};

export const useLanguagesQuery = () => {
  return useQuery({
    staleTime: STALE_TIME,
    queryKey: [QueryKey.GET_LANGUAGES],
    queryFn: () =>
      apiEndpoint<Array<LanguageDto>>(
        `${REACT_APP_API_URL}/languages`,
        {},
        "GET"
      ),
  });
};

export const employerSignUpaslabourer = async (
  registerData: Record<string, any>
) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/users/become-labourer`,
    registerData,
    "POST"
  );
};
export const useUpdateProfileImageMutation = () => {
  return useMutation(async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    const x = await apiEndpointFile(
      `${REACT_APP_API_URL}/profile/image/update`,
      formData,
      "PUT"
    );

    return x;
  });
};

export const registerFcmToken = async (payload: FcmTokenPayload) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/users/devices`,
    payload,
    "POST"
  );
};

export const useServiceCategoryQuery = () => {
  return useQuery({
    queryKey: [QueryKey.GET_SERVICE_CATEGORY],
    queryFn: () =>
      apiEndpoint<Array<Service>>(`${REACT_APP_API_URL}/services`, {}, "GET"),
    staleTime: STALE_TIME,
  });
};

export const fetchUser = () => {
  //console.log("fetchUser called");
  return apiEndpoint<UserDetailResponse>(
    `${REACT_APP_API_URL}/users/me`,
    {},
    "GET"
  );
};

export const useUserMeQuery = (autoQuery: boolean = true) => {
  return useQuery(
    [QueryKey.GET_USERS, _token()],
    () =>
      apiEndpoint<UserDetailResponse>(
        `${REACT_APP_API_URL}/users/me`,
        {},
        "GET"
      ),
    {
      enabled: isAuthenticated() && autoQuery,
      staleTime: STALE_TIME,
    }
  );
};

export const useChatMessageQuery = (chatRoomId: number) => {
  return useQuery<any, any, IMessage[], any>({
    queryKey: [QueryKey.GET_CHATS, chatRoomId],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/chat-messages/${chatRoomId}`,
        {},
        "GET"
      ),
    refetchOnWindowFocus: true,
  });
};

export const useChatLabourerData = () => {
  return useQuery({
    queryKey: [QueryKey.GET_CHAT_MESSAGES],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/chat-messages`,
        {},
        "GET"
      ),
    refetchOnWindowFocus: true,
  });
};

// export const favouritesLabourer = async (labourerId: number) => {
//   const requestData: Record<string, any> = { labourerId };
//   return apiEndpoint<Record<string, any>>(
//     `${REACT_APP_API_URL}/favourites`,
//     requestData,
//     "POST"
//   );
// };

export const useLabourerDetails = async (id: number) => {
  return useQuery({
    queryKey: [QueryKey.GET_LABOURER_DETAILS],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/labourer/${id}`,
        {},
        "GET"
      ),
  });
};

export const createJob = async (awardData: CreateJobRequest) => {
  return apiEndpoint<JobDto>(`${REACT_APP_API_URL}/job`, awardData, "POST");
};

export const useJobAwardedList = (
  offset: number,
  limit: number,
  isOffered: boolean
) => {
  return useQuery({
    queryKey: [QueryKey.GET_JOB_AWARDS, offset, limit],
    queryFn: () =>
      apiEndpoint<JobResponse>(
        `${REACT_APP_API_URL}/jobs?limit=${limit}&offset=${offset}&isOffered=${isOffered}`,
        {},
        "GET"
      ),
  });
};

export const jobAwardAction = async (awardData: Record<string, any>) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/job/award`,
    awardData,
    "PATCH"
  );
};

export const jobAwardActionPost = async (awardData: Record<string, any>) => {
  return apiEndpoint<Record<string, any>>(
    `${REACT_APP_API_URL}/job/award`,
    awardData,
    "POST"
  );
};
export const jobChatRoom = async (chatRoomData: Record<string, any>) => {
  return apiEndpoint(
    `${REACT_APP_API_URL}/job-chat-room`,
    chatRoomData,
    "POST"
  );
};

export const updateEmployerAvailable = async () => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/availability/available`,
    {},
    "PUT"
  );
};

export const updateEmployerBusy = async () => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/availability/unavailable`,
    {},
    "PUT"
  );
};

export const useAvailability = (userId: number | undefined) => {
  const queryParams = `employeeId=${userId}`;
  return useQuery({
    queryKey: [QueryKey.GET_AVAILABILITY, userId],
    select: (data) => (data.length == 0 ? undefined : data[0]),
    queryFn: () =>
      apiEndpoint<UserAvailability[]>(
        `${REACT_APP_API_URL}/availability?${queryParams}`,
        {},
        "GET"
      ),
    enabled: isAuthenticated() && userId != undefined,
  });
};

export const useEmployerAvailability = (employerId: any[]) => {
  if (!Array.isArray(employerId) || employerId.length === 0) {
    employerId = [];
  }
  const queryParams =
    employerId &&
    employerId.map((employer) => `employeeId=${employer?.userId}`).join("&");
  const enabled = employerId && employerId.length > 0;
  return useQuery({
    queryKey: [QueryKey.GET_LABOURER_DATA],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/availability?${queryParams}`,
        {},
        "GET"
      ),
    enabled: enabled,
  });
};

export const useJobStatisticsData = (data: boolean) => {
  return useQuery({
    queryKey: [QueryKey.GET_JOB_STATISTICS],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/job-statistics?asLabourer=${data}`,
        {},
        "GET"
      ),
  });
};

export const useGetChatList = () => {
  return useQuery<any, any, ChatRoomDto[], any>({
    queryKey: [QueryKey.GET_CHATLIST],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/job-chat-rooms`,
        {},
        "GET"
      ),
  });
};

export const acknowledgeCookieAcceptance = (isAccepted: boolean) => {
  smartStorage.setItem("CookieAcceptance", String(isAccepted));
};

export const hasCookieAcceptanceAcknowledged = () => {
  return smartStorage.getItem("CookieAcceptance") === String(true);
};

export const useSkillAttributes = (skillIds: number[]) => {
  if (!Array.isArray(skillIds) || skillIds.length === 0) {
    skillIds = [];
  }
  const queryParams = skillIds.map((id) => `skill=${id}`).join("&");
  const enabled = skillIds.length > 0;
  return useQuery({
    queryKey: [QueryKey.GET_SKILL_ATTRIBUTES, skillIds],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/skill-attributes/find?${queryParams}`,
        {},
        "GET"
      ),
    enabled: isAuthenticated() && enabled,
  });
};

export const addSkillAttributesValue = async (
  skillAttributeValue: LabourerSkillAttributeDto[]
) => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/labourer-skill-attributes-values`,
    skillAttributeValue,
    "POST"
  );
};

export const usePaymentCardData = () => {
  return useQuery({
    staleTime: STALE_TIME,
    queryKey: [QueryKey.GET_PAYMENT],
    queryFn: () =>
      apiEndpoint<Array<GetPaymentCardDto>>(
        `${REACT_APP_API_URL}/subscriptions/subscription-plans`,
        {},
        "GET"
      ),
  });
};

export const usePreviousSearchDetails = (userId: any) => {
  return useQuery({
    staleTime: STALE_TIME,
    queryKey: [QueryKey.SEARCH_HISTORY_DATA, userId],
    queryFn: () =>
      apiEndpoint<Record<string, any>>(
        `${REACT_APP_API_URL}/search-history/${userId}`,
        {},
        "GET"
      ),
  });
};

export const deleteSearchHistoy = async (userId: number) => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/search-history/${userId}`,
    {},
    "DELETE"
  );
};

// const FIFTEEN_MINUTES = 900000;

export const useTotalUnreadCount = () => {
  return useQuery<TotalUnreadCountDto>({
    queryKey: [QueryKey.CHAT_COUNT],
    queryFn: () =>
      apiEndpoint<TotalUnreadCountDto>(
        `${REACT_APP_API_URL}/job-chat-rooms/total-unread-count`,
        {},
        "GET"
      ),
    // refetchInterval: FIFTEEN_MINUTES,
  });
};

export const useUnreadCountList = () => {
  return useQuery<UnreadCountListDto>({
    queryKey: [QueryKey.CHAT_COUNTLIST],
    queryFn: () =>
      apiEndpoint<UnreadCountListDto>(
        `${REACT_APP_API_URL}/job-chat-rooms/unread-count-list`,
        {},
        "GET"
      ),
  });
};

export const resetChatList = async (roomId: any) => {
  return apiEndpoint<any>(
    `${REACT_APP_API_URL}/job-chat-rooms/reset-unread-count`,
    roomId,
    "POST"
  );
};

export const useReferralStatus = () => {
  return useQuery<ReferralStatusDto>({
    queryKey: [QueryKey.REFERRAL_STATUS],
    queryFn: () =>
      apiEndpoint<ReferralStatusDto>(
        `${REACT_APP_API_URL}/referral-controller/user-referral-stats`,
        {},
        "GET"
      ),
  });
};

function buildQueryParams(params: Record<string, any>): string {
  const queryParams = new URLSearchParams();

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const value = params[key];
      if (value !== null && value !== undefined) {
        if (Array.isArray(value)) {
          value.forEach((item: any) => {
            queryParams.append(key, String(item));
          });
        } else {
          queryParams.append(key, String(value));
        }
      }
    }
  }

  return queryParams.toString();
}

export const useSearchLabourer = (
  lat: number | null | undefined,
  lng: number | null | undefined,
  searchId: number | null,
  languages: number[],
  skill: number[],
  page: number,
  limit: number,
  selectedSortOption: string
) => {
  const languageQueryString = languages
    .map((lang) => `languages=${lang}`)
    .join("&");

  const allSkills = [...skill, searchId].filter((it) => it != null);
  const uniqueSkills = Array.from(new Set(allSkills));
  const skillQueryString =
    uniqueSkills.length > 0
      ? uniqueSkills.map((skill) => `skill=${skill}`).join("&")
      : `skill=${searchId}`;
  const enabled = searchId ? true : false;

  const params = {
    lat: lat ?? 0,
    lng: lng ?? 0,
    page: page,
    order: selectedSortOption,
    limit: limit,
    languages: languages,
    skill: uniqueSkills,
  };

  const query = buildQueryParams(params);

  return useQuery({
    queryKey: [
      QueryKey.GET_SEARCH_LABOURER,
      lat,
      lng,
      languageQueryString,
      skillQueryString,
      page,
      limit,
      selectedSortOption,
    ],
    queryFn: () =>
      apiEndpoint<SearchResultResponse>(
        `${REACT_APP_API_URL}/labourer/search?${query}`,
        {},
        "GET"
      ),
    enabled: enabled,
    staleTime: STALE_TIME,
    retry: false,
  });
};

export const useGetFaqs = () => {
  return useQuery({
    queryKey: [QueryKey.GET_FAQS],
    queryFn: () =>
      apiEndpoint<FaqsDto[]>(`${REACT_APP_API_URL}/faqs`, {}, "GET"),
  });
};
export const addBankDetails = async (bankDetails: any) => {
  return apiEndpoint<any>(
    `${REACT_APP_API_URL}/user-bank-accounts`,
    bankDetails,
    "POST"
  );
};

export const useGetBankDetails = () => {
  return useQuery({
    queryKey: [QueryKey.GET_BANK_DETAILS],
    queryFn: () =>
      apiEndpoint<BankDetailsDto[]>(
        `${REACT_APP_API_URL}/user-bank-accounts`,
        {},
        "GET"
      ),
  });
};

export const updateBankDetails = async (bankData: BankDetailsDto) => {
  return apiEndpoint<BankDetailsDto[]>(
    `${REACT_APP_API_URL}/user-bank-accounts/${bankData?.id}`,
    bankData,
    "PATCH"
  );
};

export const updateJobCompleted = async (jobData: JobStatusUpdateDto) => {
  return apiEndpoint<JobStatusUpdateDto[]>(
    `${REACT_APP_API_URL}/job/award`,
    jobData,
    "PATCH"
  );
};

export const GetMonthlyProfileViews = (labourerId: number | undefined) => {
  const queryParams = `labourerId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_MONTHLY_PROFILE_REVIEWS],
    queryFn: () =>
      apiEndpoint<ProfileViewMonthDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/monthlyProfileViews?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const GetTotalProfileViews = (labourerId: number | undefined) => {
  const queryParams = `labourerId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_TOTAL_PROFILE_REVIEWS],
    queryFn: () =>
      apiEndpoint<TotalProfileViewDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/totalProfileViews?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const GetOverallRatings = (labourerId: number | undefined) => {
  const queryParams = `labourerId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_OVERALL_RATING],
    queryFn: () =>
      apiEndpoint<OverallRatingDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/labourerOverallRatings?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const GetLabourerSkillRatings = (labourerId: number | undefined) => {
  const queryParams = `labourerId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_LABOURER_SKILL_RATING],
    queryFn: () =>
      apiEndpoint<SkillRateDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/labourerSkillRatings?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const useReferralIncentiveLegends = (labourerId: number | undefined) => {
  const queryParams = `labourerUId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_REFERRAL_INCENTIVE_LEGENDS],
    queryFn: () =>
      apiEndpoint<ReferralIncentiveLegendsDto>(
        `${REACT_APP_API_URL}/labourer-dashboard/referralIncentiveLegends?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const GetMonthlyReferralIncentives = (
  labourerId: number | undefined
) => {
  const queryParams = `labourerUId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_MONTHLY_REFERRAL_INCENTIVES],
    queryFn: () =>
      apiEndpoint<IncentiveDataDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/monthlyReferralIncentives?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const usePaymentTransaction = (labourerId: number | undefined) => {
  const queryParams = `labourerUId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_PAYIN_HISTORY],
    queryFn: () =>
      apiEndpoint<PaymentTransactionDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/paymentTransactions?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const useSubscriptionDetails = (labourerId: number | undefined) => {
  const queryParams = `labourerUId=${labourerId}`;
  return useQuery({
    queryKey: [QueryKey.GET_SUBSCRIPTION_DETAILS],
    queryFn: () =>
      apiEndpoint<UserSubscriptionDto[]>(
        `${REACT_APP_API_URL}/labourer-dashboard/subscriptionDetails?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const usePayInHistory = () => {
  return useQuery({
    queryKey: [QueryKey.GET_PAYIN_HISTORY],
    queryFn: () =>
      apiEndpoint<PayInTransactionsHistory[]>(
        `${REACT_APP_API_URL}/payment-transaction-controller/history`,
        {},
        "GET"
      ),
  });
};

export const useNotificationCounts = () => {
  return useQuery({
    queryKey: [QueryKey.GET_NOTIFICATION_COUNTS],
    queryFn: () =>
      apiEndpoint<NotificationCountDto[]>(
        `${REACT_APP_API_URL}/notifications/getNotificationCounts`,
        {},
        "GET"
      ),
  });
};

export const useUnreadNotifications = (page: number, limit: number) => {
  const queryParams = `pageNum=${page}&pageSize=${limit}`;
  return useQuery({
    queryKey: [QueryKey.GET_UNREAD_NOTIFICATIONS, page, limit],
    queryFn: () =>
      apiEndpoint<UnreadNotificationDto[]>(
        `${REACT_APP_API_URL}/notifications/getUnreadNotifications?${queryParams}`,
        {},
        "GET"
      ),
  });
};

export const changeStatusToRead = async (ids: number[]) => {
  const queryParams = ids.map((id) => `id=${id}`).join("&");
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/notifications/changeStatusToRead?${queryParams}`,
    {},
    "PATCH"
  );
};

export const sendFeedback = async (feedbackDetails: FeedbackDetailsDto) => {
  return apiEndpoint<FeedbackDetailsDto>(
    `${REACT_APP_API_URL}/feedbacks`,
    feedbackDetails,
    "POST"
  );
};

export const usePayOutHistory = () => {
  return useQuery({
    queryKey: [QueryKey.GET_PAYOUT_HISTORY],
    queryFn: () =>
      apiEndpoint<PayOutTransactionsHistory[]>(
        `${REACT_APP_API_URL}/payout/history`,
        {},
        "GET"
      ),
  });
};

export const deleteReview = async (id: number) => {
  return apiEndpoint<NoContentResponse>(
    `${REACT_APP_API_URL}/employer-reviews/${id}`,
    {},
    "DELETE"
  );
};

export const useGeneralSettings = () => {
  return useQuery({
    queryKey: [QueryKey.GET_GENERAL_SETTINGS],
    queryFn: () =>
      apiEndpoint<GeneralDataDto>(
        `${REACT_APP_API_URL}/general-settings/loadClientConfiguration?platform=web&version=1.0.0`,
        {},
        "GET"
      ),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
};

export const useLabourerDashboardLegend = (labourerId: number | undefined) => {
  return useQuery({
    queryKey: [QueryKey.GET_LABOURER_LEGENDS, labourerId],
    queryFn: () => {
      if (labourerId !== undefined) {
        const queryParams = `labourerId=${labourerId}`;
        return apiEndpoint<any>(
          `${REACT_APP_API_URL}/labourer-dashboard/legends?${queryParams}`,
          {},
          "GET"
        );
      }
      return Promise.resolve(undefined);
    },
    enabled: labourerId !== undefined,
  });
};
