import React, { useState } from "react";
import { FaLinkedinIn } from "react-icons/fa";
import { GrFacebookOption } from "react-icons/gr";
import { BsTwitter } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";
const nev = {
  Services: [
    {
      name: "TermsCondition",
      href: "terms-and-conditions"
    },
    {
      name: "PrivacyPolicy",
      href: "privacy-policy",
      submenu: [
        {
          name: "RefundPolicy",
          href: "refundcancellation"
        }
      ]
    },
    {
      name: "Acceptable Policy",
      href: "acceptable-policy"
    },
    {
      name: "Disclaimer Policy",
      href: "disclaime-policy"
    }
  ],
  Help: [
    // {
    //   name: "Help",
    //   href: "help"
    // },
    {
      name: "FAQ",
      href: "faqs"
    },
    {
      name: "Report Abuse",
      href: "report-abuse"
    }
  ],
  Services_Demo: [
    {
      name: "Ads/Commercials"
    },
    {
      name: "AdvertiseOnEaidex"
    }
  ],
  About: [
    {
      name: "AboutUs",
      href: "aboutus"
    },
    {
      name: "ContactUs",
      href: "contact-us"
    },
    {
      name: "Careers"
    }
  ],
  Press: [
    {
      name: "Blogs"
      // href: "blogs"
    }
  ]
};

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [subMenu, setSubMenu] = useState<number | null>();
  const handleMenuClick = (href: any) => {
    navigate(href); // Navigate to the specified href
  };

  return (
    <>
      <div className="bg-[#272D29] pt-[109px] pb-[33px]">
        <div className="4xl:max-w-full 4xl:px-[90px] xl:max-w-[1186px] 2xl:max-w-[1440px] mx-auto  gap-[100px]  md:flex block px-5">
          <div className="w-[100%] flex flex-wrap gap-[20px] justify-between ">
            <div>
              <h4 className="4xl:text-[1.3vw] text-[#F0A61F] mb-3 text-[23px] font-semibold">
                {t("About")}
              </h4>
              <ul className="space-y-1.5">
                {nev.About.map((items, index) => {
                  return (
                    <li
                      key={index}
                      className="4xl:text-[1vw] text-[18px] text-white "
                    >
                      <a href={items.href}>{t(items.name)}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <h4 className="4xl:text-[1.3vw] text-[#F0A61F] mb-3 text-[23px] font-semibold">
                {t("Policies")}
              </h4>
              <ul className="space-y-1.5">
                {nev.Services.map((items, index) => {
                  return (
                    <li
                      key={index}
                      className="4xl:text-[1vw] relative text-[18px] text-white"
                    >
                      <a href={items.href}>{t(items.name)}</a>
                      {items.submenu && (
                        <span
                          className={`${
                            subMenu === index ? "transform rotate-180" : ""
                          } ml-2 inline-block`}
                          onClick={() =>
                            setSubMenu(subMenu === index ? null : index)
                          }
                        >
                          <AiFillCaretDown
                            className="text-[18px]"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {subMenu === index && items?.submenu && (
                        <ul>
                          {items?.submenu.map((item, subIndex) => (
                            <li className="pl-3" key={subIndex}>
                              <a href={item.href}>{t(item.name)}</a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <h4 className="4xl:text-[1.3vw] text-[#F0A61F] mb-3 text-[23px] font-semibold">
                {t("Advertisement")}
              </h4>
              <ul className="space-y-1.5">
                {nev.Services_Demo.map((items, index) => {
                  return (
                    <li
                      key={index}
                      className="4xl:text-[1vw] text-[18px] text-white "
                    >
                      {/*<a href={items.href}>{t(items.name)}</a>*/}
                      {t(items?.name)}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <h4 className="4xl:text-[1.3vw] text-[#F0A61F] mb-3 text-[23px] font-semibold">
                {t("Help")}
              </h4>
              <ul className="space-y-1.5">
                {nev.Help.map((items, index) => {
                  return (
                    <li
                      key={index}
                      className="4xl:text-[1vw] text-[18px] text-white "
                    >
                      <a href={items.href}>{t(items.name)}</a>
                    </li>
                  );
                })}
              </ul>
            </div>
            {/* <div>
              <h4 className="text-[#F0A61F] mb-12 text-[23px] font-semibold"></h4>
              <ul className="space-y-1.5">
                {nev.Services.map((items, index) => {
                  return (
                    <li key={index} className="4xl:text-[1vw] text-[18px] text-white ">
                      <a href={items.href}>{t(items.name)}</a>
                    </li>
                  );
                })}
              </ul>
            </div> */}

            <div>
              <h4 className="4xl:text-[1.3vw] text-[#F0A61F] mb-3 text-[23px] font-semibold">
                {t("Press")}
              </h4>
              <ul className="space-y-1.5">
                {nev.Press.map((items, index) => {
                  return (
                    <li
                      key={index}
                      className="4xl:text-[1vw] text-[18px] text-white "
                    >
                      {/*<a href={items.href}>{t(items.name)}</a>*/}
                      {t(items.name)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center justify-end gap-[9px]">
              <button className="bg-[#D9D9D9] flex items-center justify-center rounded-full 4xl:w-[70px] 4xl:h-[70px] w-[33px] h-[33px] text-black">
                <GrFacebookOption className="4xl:text-[30px] text-base" />
              </button>
              <button className="bg-[#D9D9D9] flex items-center justify-center rounded-full 4xl:w-[70px] 4xl:h-[70px] w-[33px] h-[33px] text-black">
                <FaLinkedinIn className="4xl:text-[30px] text-base" />
              </button>
              <button className="bg-[#D9D9D9] flex items-center justify-center rounded-full 4xl:w-[70px] 4xl:h-[70px] w-[33px] h-[33px] text-black">
                <BsTwitter className="4xl:text-[30px] text-base" />
              </button>
            </div>

            <a
              href="/contact-us"
              className="gap-1.5 mt-[23px] 4xl:text-[1vw] 4xl:py-5 text-[17px] leading-[13px] font-medium py-[14px] px-[24px] rounded-full bg-[#F0A61F]"
            >
              {t("ContactUs")}
            </a>

            <div className="mt-[28px] gap-[14px] flex flex-col items-end w-full">
              {/* <button className="overflow-hidden rounded-lg outline-none">
                <img
                  src={require("../assets/images/appstore.png")}
                  className="w-full"
                  alt=""
                />
              </button> */}
              <button className="overflow-hidden rounded-lg outline-none">
                <img
                  src={require("../assets/images/playstore.png")}
                  className="w-full"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
        <div className="border-t-[#FFFFFF33] border-t-[1px] mt-[38px]">
          <div className="flex pt-[24px] flex-wrap md:justify-between justify-center xl:max-w-[1186px] 2xl:max-w-[1440px]  mx-auto items-center gap-[28px] px-5">
            <p className="text-white 4xl:text-[1vw] text-[16px] opacity-80 ">
              {t("AllRightReseved", { _year: new Date()?.getFullYear() })}
            </p>
            <ul className="flex flex-wrap justify-center items-center gap-[25px]">
              <li className="text-white 4xl:text-[1vw] text-[16px] opacity-80 cursor-pointer ">
                <a href="/privacy-policy">{t("PrivacyPolicy")}</a>
              </li>
              <li className="text-white 4xl:text-[1vw] text-[16px] opacity-80 cursor-pointer">
                <a href="/terms-and-conditions">{t("TermsCondition")}</a>
              </li>
              <li className="text-white 4xl:text-[1vw] text-[16px] opacity-80 cursor-pointer ">
                <a href="/acceptable-policy">{t("Acceptable Policy")}</a>
              </li>
              <li className="text-white 4xl:text-[1vw] text-[16px] opacity-80 cursor-pointer">
                <a href="/disclaime-policy">{t("Disclaimer Policy")}</a>
              </li>
              <li className="text-white 4xl:text-[1vw] text-[16px] opacity-80 cursor-pointer">
                <a href="/refund-and-cancellation">{t("RefundPolicy")}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <div className="bg-[#272D29] border-t py-[26px]"> */}

      {/* </div> */}
    </>
  );
};

export default Footer;
