import { useTranslation } from "react-i18next";
import JobPieChart from "../component/LabourerChart/JobPieChart";
import ProfileViewsChart from "../component/LabourerChart/ProfileViewChart";
import RatingChart from "../component/LabourerChart/RatingChart";
import PaymentChart from "../component/LabourerChart/PaymentChart";
import ProfileSummary from "../component/LabourerChart/ProfileSummary";
import ReferralIncentivesChart from "../component/LabourerChart/ReferralIncentivesChart";
import { UserRole } from "../API/types";
import { useUserMeQuery } from "../API/api";
import { hasRole } from "../utils/utils";
import SubscriptionDetails from "../component/LabourerChart/SubscriptionDetails";
import SearchHistory from "../component/LabourerChart/SearchHistory";
import FavouriteListChart from "../component/LabourerChart/FavouriteListChart";
import RecentReviewChart from "../component/LabourerChart/RecentReviewChart";
import JobPieChartEmployer from "../component/LabourerChart/JobChartEmployer";

const Dashboard = () => {
  const { t } = useTranslation();
  const { data: userRoleData } = useUserMeQuery();

  return (
    <div className="mainDiv px-4 py-4">
      <ProfileSummary userRole={userRoleData} />

      <div className="secondSubDiv  w-full  mt-5">
        {/* 1ST PART */}
        {hasRole(userRoleData?.user, UserRole.LABOURER) ? (
          <>
            <div className="grid md:grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 2xl:grid-cols-2  grid-cols-1 gap-4 mb-4">
              <div className="bg-white jobsMaindiv flex flex-col items-center justify-center pl-4  pt-2 rounded-md shadow-md">
                <ProfileViewsChart />
              </div>

              <div className="bg-white jobsMaindiv flex flex-col items-center justify-center pl-4 rounded-md shadow-md">
                <JobPieChart />
              </div>
            </div>
          </>
        ) : null}
        {hasRole(userRoleData?.user, UserRole.EMPLOYER) ? (
          <>
            {/* 1ST PART */}
            <div className="grid md:grid-cols-1 lg:grid-cols-2  xl:grid-cols-2 2xl:grid-cols-2  grid-cols-1 gap-4">
              {/* <ProfileViewsChart /> */}
              <SearchHistory />

              <div className="bg-white jobsMaindiv flex flex-col items-center justify-center pl-4 rounded-md shadow-md">
                <JobPieChartEmployer />
              </div>
            </div>
          </>
        ) : null}

        {/* 2ND PART */}
        {hasRole(userRoleData?.user, UserRole.LABOURER) ? (
          <>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2  grid-cols-1 gap-4 mt-4">
              <div className="bg-white jobsMaindiv flex flex-col items-center justify-center pl-4 rounded-md shadow-md">
                <PaymentChart />
              </div>
              <RatingChart />
            </div>
          </>
        ) : null}

        {/* LAST PART  */}
        {hasRole(userRoleData?.user, UserRole.LABOURER) ? (
          <>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2  grid-cols-1 gap-4 mt-4">
              <div className="bg-white rounded-lg pl-4 pt-3  shadow-md">
                <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto">
                  {t("My Subscription")}
                </span>
                <SubscriptionDetails />
              </div>
              <div className="bg-white rounded-lg p-2 shadow-md">
                <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto pb-4">
                  {t("Referral Incentives")}
                </span>

                <ReferralIncentivesChart />
              </div>
            </div>
          </>
        ) : null}
        {hasRole(userRoleData?.user, UserRole.EMPLOYER) ? (
          <>
            <div className="grid md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2  grid-cols-1 gap-4 mt-4">
              <RecentReviewChart />
              <FavouriteListChart />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Dashboard;
