import { useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { DeleteIcon, EditIcon, Review1 } from "../assets/images/AllSvg";
import ReviewDiv from "../component/Details/ReviewDiv";
import { StarIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetFavourites,
  useLanguagesQuery,
  useUserMeQuery,
  useServiceCategoryQuery,
} from "../API/api";

import { isAuthenticated } from "../utils/jwtUtils";
import { classNames, isLabourer } from "../utils/utils";
import { useEmployerReviewObtained } from "../API/reviewsApi";
import {
  useAddFavouriteMutation,
  useRemoveFavouriteMutation,
} from "../component/services/Mutation";
import { handleFavorites } from "../utils/favoritesUtils";
import BecomeLabourerModal from "../component/Models/BecomeLabourerModal";
import { useJob } from "../hooks/useJob";
import SkillSelectionPopup from "../component/SkillSelectionPopup/SkillSelectionPopup";
import { useLocationContext } from "../component/Context/LocationContext";

interface LabourerDetailProps {
  setOpen: any;
}
interface FavoriteLoadingState {
  [key: number]: {
    add: boolean;
    remove: boolean;
  };
}
const Details = ({ setOpen }: LabourerDetailProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: languages } = useLanguagesQuery();
  const { data: userData } = useUserMeQuery();
  const { data: serviceData } = useServiceCategoryQuery();
  const [openPopup, setOpenPopup] = useState(false);
  const [showReview, setShowReview] = useState(false);
  const location = useLocation();
  const details = location?.state?.details;
  const allResult = location?.state?.alldata;
  const skill = location?.state?.skill;
  const favLabourerId = location?.state?.favLabourerId;
  const isFav = location?.state?.isFav;
  const [show, setShow] = useState({ id: 0, status: false });
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any | null>(null);
  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);
  const [currentlySelectedSkill, setCurrentlySelectedSkill] = useState<
    any | null
  >(null);
  const [currentActionType, setCurrentActionType] = useState<string | null>(
    null
  );

  // USE JOB HOOK
  const {
    performAction,
    chatRoomLoadingState,
    awardJobLoadingState,
    createJobError,
    openAwardMenu,
    setOpenAwardMenu,
  } = useJob();

  // Geolocation logic
  const { state } = useLocationContext();

  const handleClick = (id: number) => {
    if (details.id === id) {
      setShow({ id: details.id, status: !show?.status });
    }
  };
  const [favoriteLoadingStates, setFavoriteLoadingStates] =
    useState<FavoriteLoadingState>({});
  const { data: favouritesData } = useGetFavourites();

  // FAVOURITE ADD AND REMOVE MUTATION
  const favouritesMutation = useAddFavouriteMutation();
  const removeFavouriteMutation = useRemoveFavouriteMutation();

  //FAVOURITE UTILS FUNCTIONS
  const handleFavoritesClick = (labourerId: number) =>
    handleFavorites(
      labourerId,
      favouritesData,
      setFavoriteLoadingStates,
      removeFavouriteMutation,
      favouritesMutation,
      setOpen,
      isAuthenticated
    );

  const handleSkillCheckboxClick = (skill: any) => {
    if (currentlySelectedSkill === skill) {
      setCurrentlySelectedSkill(null);
    } else {
      setCurrentlySelectedSkill(skill);
    }
  };

  const handleChatButtonClick = (item: any) => {
    if (isFav && !isConfirmationModalShown) {
      openConfirmationModal("chat", item);
    } else {
      performAction("chat", item);
    }
  };

  const handleAwardButtonClick = (item: any) => {
    if (isFav && !isConfirmationModalShown) {
      openConfirmationModal("award", item);
    } else if (isLabourer(userData?.user)) {
      setShowConfirm(true);
      setSelectedItem(item);
    } else {
      performAction("award", item);
    }
  };

  const handleConfirm = () => {
    setShowConfirm(false);
    if (selectedItem) {
      performAction("award", selectedItem);
    }
  };

  const handleCancel = () => {
    setShowConfirm(false);
  };

  // OPEN THE CONFIRMATION MODAL AND SET THE ACTION TYPE
  const openConfirmationModal = (actionType: string, item: any) => {
    setOpenPopup(true);
    setSelectedItem(item);
    setCurrentActionType(actionType);
  };

  // HANDLE CONTINUING AFTER SELECTING SKILL AND LOCATION IN THE POPUP
  const handleConfirmationModalContinue = () => {
    setOpenPopup(false);
    setIsConfirmationModalShown(true);

    if (selectedItem && currentlySelectedSkill ) {
      const searchResult = {
        ...selectedItem,
        location: state?.selectedLocationData?.city,
        lat: state?.selectedLocationData?.lat,
        lng: state?.selectedLocationData?.lng,
        profileUrl: selectedItem?.labourerimage,
        userId: selectedItem?.skills[0].userId,
        skills: [{ skillId: currentlySelectedSkill.skillId }],
      };
      if (currentActionType === "chat") {
        performAction("chat", searchResult);
      } else if (currentActionType === "award") {
        performAction("award", searchResult);
      }
    }
  };

  const { data: reviewsData } = useEmployerReviewObtained(details?.userId);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div>
        <div className="max-w-[811px] mt-[45px] sm:px-5 px-3 mx-auto ">
          <button
            onClick={() => {
              showReview ? setShowReview(false) : navigate(-1);
            }}
            className="flex items-center gap-2 mb-[10px] text-[18px]"
          >
            <BiLeftArrowAlt className="text-[#0A2640] text-[24px]" />{" "}
            {t("Back")}
          </button>
        </div>
        {!showReview ? (
          <ReviewDiv
            details={details}
            handleJobAwardClick={handleAwardButtonClick}
            handleChatClick={handleChatButtonClick}
            handleFavorites={handleFavoritesClick}
            handleClick={handleClick}
            show={show}
            languages={languages}
            skill={skill}
            reviewsData={reviewsData}
            setShowReview={setShowReview}
            allResult={allResult}
            openAwardMenu={openAwardMenu}
            setOpenAwardMenu={setOpenAwardMenu}
            favoriteLoadingStates={favoriteLoadingStates}
            favouritesData={favouritesData}
            favLabourerId={favLabourerId}
            chatRoomLoadingState={chatRoomLoadingState}
            awardJobLoadingState={awardJobLoadingState}
            createJobError={createJobError}
            isFav={isFav}
            userData={userData}
          />
        ) : (
          <div className="pb-[19px] mx-auto flex flex-col mt-[52px] max-w-[811px] sm:px-5 px-3">
            <div className="border-[#322f1d] rounded-[11px] min-w-[946px] overflow-x-auto">
              <div className="border p-[15px] flex items-center justify-between border-[#D1D0CA] rounded-t-[11px] pl-[24px]">
                <h5 className="text-[#F0A61F] text-[18px] font-semibold">
                  {t("Reviews")}
                </h5>
              </div>
              <div className="[&>*:last-child]:rounded-b-[11px] mb-[190px]">
                <div className="border border-[#D1D0CA]">
                  {reviewsData?.employerReviews?.map((item: any) => {
                    return (
                      <>
                        <div className="border-b  border-[#D1D0CA] p-3">
                          <div className="2xl:pr-[53px] px-3 2xl:pl-[35px] p-3 pb-4 flex flex-wrap justify-between items-center">
                            <div className="flex min-w-[130px] justify-between items-center flex-col">
                              <Review1 />
                              <h3 className="text-lg font-semibold text-[#222222]">
                                {
                                  reviewsData?.users?.find(
                                    (user: any) => user.id === item.job.userId
                                  )?.name
                                }
                              </h3>
                            </div>
                            <h2 className="text-[20px] text-center font-semibold text-[#222222]">
                              "Job Title"
                            </h2>
                            <p className="text-base text-center max-w-[376px] w-full font-medium text-[#222222]">
                              {item?.review}
                            </p>
                            <div className="flex star">
                              {[0, 1, 2, 3, 4].map((rating) => (
                                <StarIcon
                                  key={`${item.id}_${rating}`}
                                  className={classNames(
                                    item?.employerRatings[0].rating > rating
                                      ? "text-[#F0A61F]"
                                      : "text-gray-200",
                                    "h-5 w-5 flex-shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                              ))}
                            </div>
                            <div className="flex items-center gap-5">
                              <button>
                                <EditIcon />
                              </button>
                              <button>
                                <DeleteIcon />
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showConfirm && (
        <BecomeLabourerModal
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      )}

      {/* MODAL FOR SKILL AND LOCATION SELECTION BEFORE CHAT OR AWARD ACTION IN FAVORITE DETAILS */}

      <SkillSelectionPopup
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        details={details}
        serviceData={serviceData}
        selectedSkill={currentlySelectedSkill}
        handleSkillCheckboxClick={handleSkillCheckboxClick}
        handleModalContinue={handleConfirmationModalContinue}
        isFav={isFav}
      />
    </>
  );
};

export default Details;
