import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import {
  PaymentTransactionDto,
  usePaymentTransaction,
  useUserMeQuery,
} from "../../API/api";
import CircularProgressBar from "../CircularProgressBar";

ChartJS.register(ArcElement, Tooltip, Legend);
const RegistrationRenewalChart: React.FC = () => {
  const { data: userData } = useUserMeQuery();
  const labourerId = userData?.labourerDetails?.userId;
  const {
    data: paymentTransactionData,
    isLoading,
    isError,
  } = usePaymentTransaction(labourerId);

  const allTotalCountsZero = (paymentTransactionData ?? []).every(
    (dataPoint: PaymentTransactionDto) => dataPoint?.totalCount === 0
  );
  const totalCountData = (paymentTransactionData ?? [])?.map(
    (dataPoint: PaymentTransactionDto) => dataPoint?.totalCount
  );

  const statusColors: { [key: string]: string } = {
    Success: "#098F57",
    Failed: "#FF4C4C",
    Pending: "#FFC107",
    Canceled: "#6C757D",
    Declined: "#DC3545",
  };

  const chartData = {
    labels: Object.keys(statusColors),
    datasets: [
      {
        label: "Payment",
        data: totalCountData,
        backgroundColor: Object.values(statusColors),
        hoverOffset: 4,
      },
    ],
  };

  const options: ChartOptions<"doughnut"> = {
    plugins: {
      datalabels: {
        color: "#fff",
        font: {
          size: 14,
        },
      },
      legend: {
        display: false,
        position: "top" as const,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            const status = context.label;
            const paymentData = paymentTransactionData?.find(
              (data: PaymentTransactionDto) => data?.txnStatus === status
            );
            if (paymentData) {
              return `Pay In: ${paymentData?.payInCount}, Pay Out: ${paymentData?.payOutCount}`;
            }
            return "";
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <>
      <div className="mb-4 flex justify-between p-2 items-center w-full ">
        <div className="">
          <span className="block font-bold 2xl:text-lg md:text-lg text-sm lg:text-lg font-roboto">
            Payment Transactions
          </span>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <CircularProgressBar />
        </div>
      ) : isError ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            Error loading data
          </div>
        </div>
      ) : allTotalCountsZero ? (
        <div className="flex items-center justify-center h-full md:pb-20 pb-10">
          <div className="text-center text-[#F0A61F] text-lg font-semibold">
            No Payment data.
          </div>
        </div>
      ) : (
        <>
          <div className="relative h-[250px] w-[250px] mb-4 pr-4">
            <Doughnut data={chartData} options={options} />
          </div>
          <div className="flex items-center">
            {chartData?.labels?.map((label, index) => (
              <div key={index}>
                <div className="flex gap-1 items-center mb-4">
                  <div
                    className="inline-block w-3 h-3 rounded-full"
                    style={{
                      backgroundColor:
                        chartData.datasets[0].backgroundColor[index],
                    }}
                  ></div>
                  <div className="lg:mr-5 2xl:mr-5 xl:mr-5 mr-2 text-[8px] font-semibold md:text-xs lg:text-xs xl:text-xs 2xl:text-xs text-black">
                    {label}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default RegistrationRenewalChart;
