import { useTranslation } from "react-i18next";
interface EmptyDataProps {
  Icon: React.ElementType;
}
export const EmptyData: React.FC<EmptyDataProps> = ({ Icon }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-full text-gray-500">
      <Icon />
      <p className="text-xl font-semibold mb-2 mt-4">
        {t("No data available")}
      </p>
      <p className="text-sm text-center max-w-xs">
        {t("Once job statistics are available, they will be displayed here.")}
      </p>
    </div>
  );
};
